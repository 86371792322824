import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import UserAcccountForm from '../UserAccountForm/UserAccountForm'

class CreateUser extends React.Component {
  constructor(props) {
    super(props)

    this.onClickCreate = this.onClickCreate.bind(this)
  }

  onClickCreate(email, username, firstName, lastName, selectedRole, warehouse, refundPolicies) {
    this.props.onSubmitCreateUser(email, username, firstName, lastName, selectedRole, warehouse, refundPolicies)
  }

  render() {
    return (
      <div>
        <Typography variant="h5" component="h1">
          Create User
        </Typography>
        <UserAcccountForm
          availableRoles={this.props.availableRoles}
          availableRefundPolicies={this.props.availableRefundPolicies}
          buttonText="Create"
          onSubmitForm={this.onClickCreate}
          {...this.props}
        />
      </div>
    )
  }
}

CreateUser.propTypes = {
  onSubmitCreateUser: PropTypes.func.isRequired,
  availableRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default CreateUser
