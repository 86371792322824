import React from 'react'
import PropTypes from 'prop-types'

import RefundForm from '../RefundForm/RefundForm'

class CreateRefund extends React.Component {
  constructor(props) {
    super(props)

    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentWillMount() {
    this.props.onGetOrder(this.props.match.params.orderId)
    this.props.onGetUser()
  }

  onFormSubmit(formData) {
    this.props.onSubmitRefund(
      this.props.order.id,
      formData.comments,
      formData.accountNumber,
      formData.returnItems,
      formData.directSuppliersName,
      formData.returnMakerEmail,
    )
  }

  render() {
    if (!this.props.order) {
      return null
    }
    return <RefundForm headline="Order return" products={this.props.order.orderItems} onSubmit={this.onFormSubmit} {...this.props} />
  }
}

CreateRefund.propTypes = {
  onSubmitRefund: PropTypes.func.isRequired,
  onGetOrder: PropTypes.func.isRequired,
  order: PropTypes.object,
}

export default CreateRefund
