import { connect } from 'react-redux'

import { requestResetPassword } from '../../../state/actions/auth'
import ForgotPassword from '../../presentational/ForgotPassword/ForgotPassword'
import { push } from 'connected-react-router'

const mapStateToProps = (state) => {
  const { error } = state.auth
  return {
    error,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmitForgotPassword: (username) => {
    dispatch(requestResetPassword(username))
  },
  onCancelClicked: () => {
    dispatch(push('/'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
