import { connect } from 'react-redux'
import { requestUsersList } from '../../../state/actions/user'

import ListUsers from '../../presentational/ListUsers/ListUsers'

const mapStateToProps = (state) => ({
  users: state.user.users,
  loading: state.user.loading,
})

const mapDispatchToProps = (dispatch) => ({
  onRefreshUsersList: () => {
    dispatch(requestUsersList())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers)
