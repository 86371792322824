import { connect } from 'react-redux'

import { requestEndpoint, updateEndpoint, deleteEndpoint } from '../../../state/actions/endpoints'
import ModifyEndpoint from '../../presentational/ModifyEndpoint/ModifyEndpoint'

const mapStateToProps = (state) => {
  return {
    endpoint: state.endpoints.endpoint,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestEndpoint: (endpointId) => {
      dispatch(requestEndpoint(endpointId))
    },
    onSubmitUpdate: (endpointId, orderUrl, orderItemUrl, username, password) => {
      dispatch(updateEndpoint(endpointId, orderUrl, orderItemUrl, username, password))
    },
    onSubmitDelete: (endpointId) => {
      dispatch(deleteEndpoint(endpointId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyEndpoint)
