import React from 'react'
import PropTypes from 'prop-types'

import Authenticated from '../../container/Authenticated'

class AccountMenu extends React.Component {
  render() {
    if (this.props.isAuthenticated) {
      return <Authenticated username={this.props.username} />
    }
    return null
  }
}

AccountMenu.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
}

export default AccountMenu
