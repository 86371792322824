const style = (theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing.unit * 3,
  },
  title: {
    flex: 1,
  },
})

export default style
