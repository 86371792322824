import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid, TextField, Button, Typography } from '@material-ui/core'

import FormErrorMessage from '../FormErrorMessage/FormErrorMessage'
import styles from './styles'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      errorUsername: false,
      verificationCode: '',
      errorVerificationCode: false,
      password: '',
      errorPassword: false,
    }

    this.onChangeUsername = this.onChangeUsername.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this)
    this.onClickResetPassword = this.onClickResetPassword.bind(this)
  }

  onChangeUsername(event) {
    this.setState({
      errorUsername: false,
      username: event.target.value,
    })
  }

  onChangePassword(event) {
    this.setState({
      errorPassword: false,
      password: event.target.value,
    })
  }

  onChangeVerificationCode(event) {
    this.setState({
      errorVerificationCode: false,
      verificationCode: event.target.value,
    })
  }

  onClickResetPassword(event) {
    event.preventDefault()
    if (this.state.username.length === 0 || this.state.password.length === 0) {
      this.setState({
        errorUsername: this.state.username.length === 0,
        errorPassword: this.state.password.length === 0,
        errorVerificationCode: this.state.verificationCode.length === 0,
      })
      return
    }

    this.props.onSubmitResetPassword(this.state.username, this.state.verificationCode, this.state.password)
  }

  render() {
    const { classes } = this.props

    return (
      <form>
        <Grid className={classes.root} container justify="center" direction="column">
          <Grid item>
            <Typography variant="body2">Please check your email for verification code, and reset new password</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={16} justify="center" direction="column">
              <Grid item>
                <TextField
                  id="username"
                  label="Username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  error={this.state.errorUsername}
                  required
                  fullWidth
                  autoComplete="on"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="verificationCode"
                  type="password"
                  label="Verification Code"
                  value={this.state.verificationCode}
                  onChange={this.onChangeVerificationCode}
                  error={this.state.errorVerificationCode}
                  required
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="password"
                  type="password"
                  label="New password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  error={this.state.errorPassword}
                  required
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              {this.props.error !== null && (
                <Grid item>
                  <FormErrorMessage message={this.props.error} />
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={8} justify="space-around" alignItems="center">
                  <Grid item>
                    <Button id="login" type="submit" onClick={this.onClickResetPassword} variant="contained">
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

ResetPassword.propTypes = {
  onSubmitResetPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(ResetPassword)
