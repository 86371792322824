import { connect } from 'react-redux'
import { requestMyAccount } from '../../../state/actions/user'

import AccountMenu from '../../presentational/Account/Account'

const mapStateToProps = (state) => {
  return {
    user: state.user.myAccount,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestUser: () => {
      dispatch(requestMyAccount())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu)
