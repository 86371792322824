import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import EndpointForm from '../EndpointForm/EndpointForm'

class CreateEndpoint extends React.Component {
  constructor(props) {
    super(props)

    this.onClickCreate = this.onClickCreate.bind(this)
  }

  onClickCreate(orderUrl, orderItemUrl, username, password) {
    this.props.onSubmitCreateEndpoint(orderUrl, orderItemUrl, username, password)
  }

  render() {
    return (
      <div>
        <Typography variant="h5" component="h1">
          Create Endpoint
        </Typography>
        <EndpointForm buttonText="Create" onSubmitForm={this.onClickCreate} />
      </div>
    )
  }
}

CreateEndpoint.propTypes = {
  onSubmitCreateEndpoint: PropTypes.func.isRequired,
}

export default CreateEndpoint
