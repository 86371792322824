import React from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Typography,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'

import FormErrorMessage from '../FormErrorMessage/FormErrorMessage'
import styles from './styles'

class SearchOrders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderId: '',
      inputError: false,
    }

    this.onChangeOrderId = this.onChangeOrderId.bind(this)
    this.onClickSearch = this.onClickSearch.bind(this)
  }

  onChangeOrderId(event) {
    this.setState({ orderId: event.target.value })
  }

  onClickSearch(event) {
    const isSubmitAllowed = this.isSubmitAllowed()
    this.setState({ inputError: !isSubmitAllowed })
    if (isSubmitAllowed) {
      this.props.onSubmitSearch(this.state.orderId)
    }
  }

  isSubmitAllowed() {
    return this.state.orderId && this.state.orderId.length > 0
  }

  render() {
    const { classes, loading } = this.props
    return (
      <Grid className={classes.root} container direction="column" spacing={24}>
        <Grid item>
          <Typography variant="h5" component="h1">
            Search Orders
          </Typography>
        </Grid>
        <Grid item>
          <FormControl aria-busy={loading} aria-describedby="progress" required fullWidth error={this.state.inputError}>
            <InputLabel>Order Identifier</InputLabel>
            <Input
              disabled={loading}
              id="orderId"
              value={this.state.orderId}
              onChange={this.onChangeOrderId}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={this.onClickSearch} disabled={loading}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {this.props.error !== null && (
            <Grid item>
              <FormErrorMessage message={this.props.error} />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={16} direction="row" justify="flex-end">
          <Grid item>
            {loading && <CircularProgress id="progress" size={24} />}
            {!loading && (
              <Button id="searchButton" onClick={this.onClickSearch} variant="contained" color="primary">
                Search
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

SearchOrders.propTypes = {
  onSubmitSearch: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export default withStyles(styles)(SearchOrders)
