const style = (theme) => ({
  returnCodeText: {
    maxWidth: '100px',
  },
  amountNumberText: {
    maxWidth: '30px',
  },
})

export default style
