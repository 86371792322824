// See https://redux.js.org/docs/recipes/ReducingBoilerplate.html
const actionCreator = (type, ...argNames) => {
  return (...args) => {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}

export default actionCreator
