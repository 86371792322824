export const REQUEST_USERS = 'REQUEST_USERS'
export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS'
export const REQUEST_USERS_FAILURE = 'REQUEST_USERS_FAILURE'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const REQUEST_USER = 'REQUEST_USER'
export const REQUEST_USER_SUCCESS = 'REQUEST_USER_SUCCESS'
export const REQUEST_USER_FAILURE = 'REQUEST_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'
export const REQUEST_MY_ACCOUNT = 'REQUEST_MY_ACCOUNT'
export const REQUEST_MY_ACCOUNT_SUCCESS = 'REQUEST_MY_ACCOUNT_SUCCESS'
export const REQUEST_MY_ACCOUNT_FAILURE = 'REQUEST_MY_ACCOUNT_FAILURE'
