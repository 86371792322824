const style = (theme) => ({
  root: {
    flex: 1,
    flexDirection: 'row-reverse',
    justify: 'flex-end',
    alignItems: 'center',
  },
})

export default style
