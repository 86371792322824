import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid, TextField, Button, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

import FormErrorMessage from '../FormErrorMessage/FormErrorMessage'
import styles from './styles'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      errorUsername: false,
    }

    this.onChangeUsername = this.onChangeUsername.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
  }

  onChangeUsername(event) {
    this.setState({
      errorUsername: false,
      username: event.target.value,
    })
  }

  onClickSubmit(event) {
    event.preventDefault()
    if (this.state.username.length === 0) {
      this.setState({
        errorUsername: this.state.username.length === 0,
      })
      return
    }

    this.props.onSubmitForgotPassword(this.state.username)
  }

  render() {
    const { classes } = this.props

    return (
      <form>
        <Grid className={classes.root} container justify="center" direction="column">
          <Grid item>
            <Typography variant="body2">Please provide your username to reset the password</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={16} justify="center" direction="column">
              <Grid item>
                <TextField
                  id="username"
                  label="Username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  error={this.state.errorUsername}
                  required
                  fullWidth
                  autoComplete="on"
                />
              </Grid>
              {this.props.error !== null && (
                <Grid item>
                  <FormErrorMessage message={this.props.error} />
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={8} justify="space-around" alignItems="center">
                  <Grid item>
                    <Button color="secondary" id="cancel" type="button" onClick={this.props.onCancelClicked} variant="contained">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      id="login"
                      type="submit"
                      onClick={this.onClickSubmit}
                      variant="contained"
                      disabled={this.state.errorUsername || isEmpty(this.state.username)}
                    >
                      Reset password
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

ForgotPassword.propTypes = {
  onSubmitForgotPassword: PropTypes.func.isRequired,
  onCancelClicked: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(ForgotPassword)
