import { RSAA } from 'redux-api-middleware'
import _ from 'lodash'

const authInject = (store) => (next) => (action) => {
  const rsaa = action[RSAA]

  // Check if action is redux-api-middleware action
  if (rsaa) {
    // Inject auth headers
    const auth = _.get(store.getState(), 'auth.tokens.AccessToken')
    if (_.identity(auth)) {
      // If the access token exists, inject it
      rsaa.headers = Object.assign({}, rsaa.headers, {
        Authorization: _.get(store.getState(), 'auth.tokens.AccessToken', ''),
      })
    }
  }

  // Pass the FSA to the next action
  return next(action)
}

export default authInject
