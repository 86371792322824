import React, { Component } from 'react'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { CssBaseline, MuiThemeProvider, createMuiTheme } from '@material-ui/core'

import './App.css'
import configureStore from './state'
import NotificationMessage from './components/container/NotificationMessage'
import Header from './components/presentational/Header/Header'
import Main from './components/presentational/Main/Main'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
})

const { persistor, store, history } = configureStore({})

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <div className="App">
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <NotificationMessage />
                <Header />
                <Main />
              </MuiThemeProvider>
            </div>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
