import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Toolbar, Button } from '@material-ui/core'

import EndpointForm from '../EndpointForm/EndpointForm'
import styles from './styles'

class ModifyEndpoint extends React.Component {
  constructor(props) {
    super(props)

    this.onClickUpdate = this.onClickUpdate.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this)
  }

  componentDidMount() {
    this.props.onRequestEndpoint(this.props.match.params.endpointId)
  }

  onClickUpdate(orderUrl, orderItemUrl, user, pass) {
    const { endpointId } = this.props.match.params
    this.props.onSubmitUpdate(endpointId, orderUrl, orderItemUrl, user, pass)
  }

  onClickDelete(event) {
    this.props.onSubmitDelete(this.props.endpoint.endpointId)
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Typography variant="h5" component="h1">
          Modify Endpoint
        </Typography>
        <Toolbar className={classes.toolbar}>
          <Button color="primary" onClick={this.onClickDelete} variant="contained">
            Delete
          </Button>
        </Toolbar>
        <EndpointForm buttonText="Update" onSubmitForm={this.onClickUpdate} endpoint={this.props.endpoint} />
      </div>
    )
  }
}

ModifyEndpoint.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestEndpoint: PropTypes.func.isRequired,
  onSubmitUpdate: PropTypes.func.isRequired,
  onSubmitDelete: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true })(ModifyEndpoint)
