import { connect } from 'react-redux'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'

import PrivateRoute from '../../presentational/PrivateRoute/PrivateRoute'

function resolveAuthorization(token) {
  if (_.isNull(token)) {
    return false
  }
  const decoded = jwtDecode(token)
  const groups = _.get(decoded, 'cognito:groups')
  return _.includes(groups, 'Admin')
}

const mapStateToProps = (state) => {
  const tokens = _.get(state, 'auth.tokens', null)
  const idToken = _.get(tokens, 'IdToken', null)
  const role = resolveAuthorization(idToken)
  // Authenticated: tokens exist, Authorized: has role Admin
  return {
    isAuthenticated: !_.isNull(tokens),
    isAuthorized: role,
  }
}

export default connect(mapStateToProps)(PrivateRoute)
