import thunk from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import { routerMiddleware } from 'connected-react-router'

import authInject from '../middleware/authInject'
import tokenRefresh from '../middleware/tokenRefresh'

const middlewaresBuilder = (history) => {
  const middlewares = [
    // Add middlewares here
    thunk,
    authInject, // This needs to be before redux-api-middleware to work
    tokenRefresh, // This needs to be before redux-api-middleware to work
    apiMiddleware,
    routerMiddleware(history),
  ]

  if (process.env.NODE_ENV === 'development') {
    // Add development only middlewares here
    // Note: logger must be the last middleware in chain, otherwise it will log thunk and promise, not actual actions
    const { logger } = require('redux-logger')
    middlewares.push(logger)
  }
  return middlewares
}

export default middlewaresBuilder
