export const REQUEST_ENDPOINTS = 'REQUEST_ENDPOINTS'
export const REQUEST_ENDPOINTS_SUCCESS = 'REQUEST_ENDPOINTS_SUCCESS'
export const REQUEST_ENDPOINTS_FAILURE = 'REQUEST_ENDPOINTS_FAILURE'
export const CREATE_ENDPOINT = 'CREATE_ENDPOINT'
export const CREATE_ENDPOINT_SUCCESS = 'CREATE_ENDPOINT_SUCCESS'
export const CREATE_ENDPOINT_FAILURE = 'CREATE_ENDPOINT_FAILURE'
export const UPDATE_ENDPOINT = 'UPDATE_ENDPOINT'
export const UPDATE_ENDPOINT_SUCCESS = 'UPDATE_ENDPOINT_SUCCESS'
export const UPDATE_ENDPOINT_FAILURE = 'UPDATE_ENDPOINT_FAILURE'
export const DELETE_ENDPOINT = 'DELETE_ENDPOINT'
export const DELETE_ENDPOINT_SUCCESS = 'DELETE_ENDPOINT_SUCCESS'
export const DELETE_ENDPOINT_FAILURE = 'DELETE_ENDPOINT_FAILURE'
export const REQUEST_ENDPOINT = 'REQUEST_ENDPOINT'
export const REQUEST_ENDPOINT_SUCCESS = 'REQUEST_ENDPOINT_SUCCESS'
export const REQUEST_ENDPOINT_FAILURE = 'REQUEST_ENDPOINT_FAILURE'
