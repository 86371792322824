import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { get, join } from 'lodash'

import { UserSchema } from '../../../schema'
import { getFinnishFormattedDate } from '../../../utils/formatting'

class Account extends React.Component {
  componentWillMount() {
    this.props.onRequestUser()
  }

  render() {
    return (
      <Grid container justify="center" direction="column" spacing={16}>
        <Grid item>
          <Typography variant="h4" component="h1">
            Account Details
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="caption">Account Created</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{getFinnishFormattedDate(get(this.props.user, 'userCreateDate', ''))}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Account Modified</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{getFinnishFormattedDate(get(this.props.user, 'userLastModifiedDate', ''))}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="caption">Account Status</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{get(this.props.user, 'userStatus', '-')}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="subtitle1">Username</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{get(this.props.user, 'username', '-')}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="subtitle1">Role</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{get(this.props.user, 'group', '-')}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="subtitle1">E-Mail</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{get(this.props.user, 'email', '-')}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="subtitle1">Name</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {get(this.props.user, 'surname', '-')} {get(this.props.user, 'name', '-')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="subtitle1">Warehouse</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{get(this.props.user, 'warehouse', '-')}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
            <Grid item>
              <Typography variant="subtitle1">Return Policies</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{join(get(this.props.user, 'Item.refundPolicies', []), ',')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Account.propTypes = {
  user: UserSchema,
}

export default Account
