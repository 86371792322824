export const SEARCH_ORDERS = 'SEARCH_ORDERS'
export const SEARCH_ORDERS_SUCCESS = 'SEARCH_ORDERS_SUCCESS'
export const SEARCH_ORDERS_FAILURE = 'SEARCH_ORDERS_FAILURE'
export const CREATE_REFUND = 'CREATE_REFUND'
export const CREATE_REFUND_SUCCESS = 'CREATE_REFUND_SUCCESS'
export const CREATE_REFUND_FAILURE = 'CREATE_REFUND_FAILURE'
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE'
export const CREATE_GENERIC_REFUND = 'CREATE_GENERIC_REFUND'
export const CREATE_GENERIC_REFUND_SUCCESS = 'CREATE_GENERIC_REFUND_SUCCESS'
export const CREATE_GENERIC_REFUND_FAILURE = 'CREATE_GENERIC_REFUNC_FAILURE'
