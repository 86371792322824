import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Popover, Grid, Typography, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import _ from 'lodash'

import styles from './styles'

class NotificationMessage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.closeNotification = this.closeNotification.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { message } = nextProps
    if (_.identity(message)) {
      this.setState({ open: true })
      this.timer = setTimeout(this.closeNotification, message.timeOut)
    }
  }

  closeNotification() {
    clearTimeout(this.timer)
    this.setState({ open: false })
    this.props.onCloseNotificationMessage(this.props.message.notificationId)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    if (!_.identity(this.props.message)) {
      return null
    }
    const { classes } = this.props
    return (
      <Popover
        open={this.state.open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={this.closeNotification}
      >
        <Grid className={classes.container} container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body1">{this.props.message.message}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={this.closeNotification}>
              <Close color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
      </Popover>
    )
  }
}

NotificationMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  onCloseNotificationMessage: PropTypes.func.isRequired,
  message: PropTypes.object,
}

export default withStyles(styles)(NotificationMessage)
