import { RSAA } from 'redux-api-middleware'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { addNotification } from './notifications'
import makeActionCreator from '../utils/makeActionCreator'
import * as ActionTypes from './types/orders'

export function searchOrders(orderId) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_ORDERS_ENDPOINT}/${orderId}`,
        method: 'GET',
        types: [ActionTypes.SEARCH_ORDERS, ActionTypes.SEARCH_ORDERS_SUCCESS, ActionTypes.SEARCH_ORDERS_FAILURE],
      },
    })

    if (actionResponse.error) {
      // TODO: Proper error handling
      return actionResponse
    }

    const orders = actionResponse.payload.message
    if (!_.isArray(orders)) {
      return dispatch({
        type: ActionTypes.SEARCH_ORDERS_FAILURE,
        payload: {
          response: {
            message: 'Unexpected response',
          },
        },
        error: true,
        meta: undefined,
      })
    }
    if (orders && orders.length === 1) {
      // Redirect user to the refund creation when only 1 result returns
      dispatch(push(`/orders/refund/${orders[0].id}`))
    } else {
      dispatch(addNotification('Found multiple orders with given id. Please select one to continue.'))
      dispatch(push('/orders'))
    }

    return actionResponse
  }
}

const getOrderSuccess = makeActionCreator(ActionTypes.GET_ORDER_SUCCESS, 'payload', 'meta')
const getOrderFailure = makeActionCreator(ActionTypes.GET_ORDER_FAILURE, 'payload', 'meta', 'error')

export function getOrder(orderId) {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.GET_ORDER, payload: undefined, meta: undefined })
    const order = _(getState().orders.orders).find((o) => o.id === orderId)
    if (order) {
      return dispatch(getOrderSuccess(order, undefined))
    }
    return dispatch(getOrderFailure(`Order with '${orderId}' not found`, undefined, true))
  }
}

export function createRefund(orderId, comments, accountNumber, returnItems, directSuppliersName, returnMakerEmail) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: process.env.REACT_APP_REFUNDS_ENDPOINT,
        method: 'POST',
        types: [ActionTypes.CREATE_REFUND, ActionTypes.CREATE_REFUND_SUCCESS, ActionTypes.CREATE_REFUND_FAILURE],
        body: JSON.stringify({
          orderId,
          comments,
          accountNumber,
          returnItems: returnItems.map((item) => ({
            ...item,
            returnedQuantity: item.notifiedQuantity, // Set both quantities to same value
          })),
          directSuppliersName,
          returnMakerEmail,
        }),
      },
    })

    if (!actionResponse.error) {
      dispatch(addNotification('Return successfully sent'))
      dispatch(push('/orders/search'))
    }
    return actionResponse
  }
}

export function createGenericRefund(
  comments,
  accountNumber,
  returnItems,
  billingAddress,
  shippingAddress,
  directSuppliersName,
  returnMakerEmail,
) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: process.env.REACT_APP_GENERIC_REFUNDS_ENDPOINT,
        method: 'POST',
        types: [ActionTypes.CREATE_GENERIC_REFUND, ActionTypes.CREATE_GENERIC_REFUND_SUCCESS, ActionTypes.CREATE_GENERIC_REFUND_FAILURE],
        body: JSON.stringify({
          returnItems,
          billingAddress,
          shippingAddress,
          comments,
          accountNumber,
          directSuppliersName,
          returnMakerEmail,
        }),
      },
    })

    if (!actionResponse.error) {
      dispatch(addNotification('Return successfully sent'))
      dispatch(push('/orders/search'))
    }
    return actionResponse
  }
}
