import { RSAA } from 'redux-api-middleware'
import { push } from 'connected-react-router'
import _ from 'lodash'

import { addNotification } from './notifications'
import * as ActionTypes from './types/user'

export function requestUser(username) {
  return async (dispatch) => {
    if (!username || username.length === 0) {
      return dispatch({
        type: ActionTypes.REQUEST_USER_FAILURE,
        payload: { message: 'Can not request user without username' },
        meta: undefined,
        error: true,
      })
    }
    return dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_USER_CRUD_ENDPOINT}/${username}`,
        method: 'GET',
        types: [ActionTypes.REQUEST_USER, ActionTypes.REQUEST_USER_SUCCESS, ActionTypes.REQUEST_USER_FAILURE],
      },
    })
  }
}

export function requestUsersList() {
  return {
    [RSAA]: {
      endpoint: process.env.REACT_APP_USER_CRUD_ENDPOINT,
      method: 'GET',
      types: [ActionTypes.REQUEST_USERS, ActionTypes.REQUEST_USERS_SUCCESS, ActionTypes.REQUEST_USERS_FAILURE],
    },
  }
}

export function createUser(email, username, firstName, lastName, role, warehouse, refundPolicies) {
  return async (dispatch) => {
    if (!email || !username || !firstName || !lastName || !role || !warehouse || !refundPolicies) {
      return dispatch({
        type: ActionTypes.CREATE_USER_FAILURE,
        payload: {
          message: 'Missing required field(s)',
        },
        error: true,
      })
    }
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_USER_CRUD_ENDPOINT}/createUser`,
        method: 'POST',
        types: [ActionTypes.CREATE_USER, ActionTypes.CREATE_USER_SUCCESS, ActionTypes.CREATE_USER_FAILURE],
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          username,
          group: role,
          warehouse,
          refundPolicies,
        }),
      },
    })

    if (actionResponse.error) {
      // TODO: Proper error handling
      return actionResponse
    }

    dispatch(addNotification('User created successfully'))
    // everything went ok, so move to user listing
    dispatch(push('/users'))
    return actionResponse
  }
}

export function updateUser(email, firstName, lastName, warehouse, refundPolicies, fetchUser) {
  return async (dispatch, getState) => {
    if (!email || !firstName || !lastName || !warehouse || !refundPolicies) {
      return dispatch({
        type: ActionTypes.UPDATE_USER_FAILURE,
        payload: {
          message: 'Missing required field(s)',
        },
        error: true,
      })
    }
    const username = _.get(getState(), 'user.user.username')
    const role = _.get(getState(), 'user.user.warehouse', '')

    // wait for update response and then fetch user data again, so it refreshes in the UI
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_USER_CRUD_ENDPOINT}/updateUser`,
        method: 'POST',
        types: [ActionTypes.UPDATE_USER, ActionTypes.UPDATE_USER_SUCCESS, ActionTypes.UPDATE_USER_FAILURE],
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          username,
          group: role,
          warehouse,
          refundPolicies,
        }),
      },
    })

    // if flagged, dont fetch updated user data
    if (!fetchUser) {
      return
    }

    if (actionResponse.error) {
      // TODO: Proper error handling
      return actionResponse
    }

    dispatch(addNotification('User updated successfully'))
    // everything went ok, so move to user listing
    dispatch(push('/users'))
    return actionResponse
  }
}

export function deleteUser(username) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_USER_CRUD_ENDPOINT}/deleteUser`,
        method: 'POST',
        types: [ActionTypes.DELETE_USER, ActionTypes.DELETE_USER_SUCCESS, ActionTypes.DELETE_USER_FAILURE],
        body: JSON.stringify({
          username,
        }),
      },
    })

    if (!actionResponse.error) {
      dispatch(addNotification('User deleted successfully'))
      dispatch(push('/users'))
    }
    return actionResponse
  }
}

export function requestMyAccount() {
  return {
    [RSAA]: {
      endpoint: process.env.REACT_APP_MYACCOUNT_ENDPOINT,
      method: 'GET',
      types: [ActionTypes.REQUEST_MY_ACCOUNT, ActionTypes.REQUEST_MY_ACCOUNT_SUCCESS, ActionTypes.REQUEST_MY_ACCOUNT_FAILURE],
    },
  }
}
