export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS'
export const REQUEST_LOGIN_FAILURE = 'REQUEST_LOGIN_FAILURE'

export const REQUEST_PASSWORD_CHANGE = 'REQUEST_PASSWORD_CHANGE'
export const REQUEST_PASSWORD_CHANGE_SUCCESS = 'REQUEST_PASSWORD_CHANGE_SUCCESS'
export const REQUEST_PASSWORD_CHANGE_FAILURE = 'REQUEST_PASSWORD_CHANGE_FAILURE'

// for the request to reset the user/password itself
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS'
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE'

// for the request to set new password for reset user
export const REQUEST_PASSWORD_RESET_CHANGE = 'REQUEST_PASSWORD_RESET_CHANGE'
export const REQUEST_PASSWORD_RESET_CHANGE_SUCCESS = 'REQUEST_PASSWORD_RESET_CHANGE_SUCCESS'
export const REQUEST_PASSWORD_RESET_CHANGE_FAILURE = 'REQUEST_PASSWORD_RESET_CHANGE_FAILURE'

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT'
export const REQUEST_LOGOUT_SUCCESS = 'REQUEST_LOGOUT_SUCCESS'
export const REQUEST_LOGOUT_FAILURE = 'REQUEST_LOGOUT_FAILURE'

export const SET_ACCESS_TOKENS = 'SET_ACCESS_TOKENS'
export const CLEAR_ACCESS_TOKENS = 'CLEAR_ACCESS_TOKENS'
