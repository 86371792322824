import createReducer from '../utils/createReducer'
import * as ActionTypes from '../actions/types/refresh'

export const initialState = {
  refreshPromise: null,
}

export default createReducer(initialState, {
  [ActionTypes.REQUEST_REFRESH_TOKEN](state) {
    return {
      ...state,
    }
  },
  [ActionTypes.REQUEST_REFRESH_TOKEN_SUCCESS](state, action) {
    return {
      ...state,
    }
  },
  [ActionTypes.REQUEST_REFRESH_TOKEN_FAILURE](state, action) {
    return {
      ...state,
      redirectToReferrer: false,
      error: action.payload.response.message,
    }
  },
  [ActionTypes.SAVE_REFRESH_PROMISE](state, action) {
    return {
      ...state,
      refreshPromise: action.payload,
    }
  },
  [ActionTypes.CLEAR_REFRESH_PROMISE](state) {
    return {
      ...state,
      refreshPromise: null,
    }
  },
})
