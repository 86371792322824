import moment from 'moment'
import _ from 'lodash'

export function getFinnishFormattedDate(input) {
  return moment(input).format('D.M.YYYY HH:mm:ss')
}

export function getFinnishFormattedMoney(input) {
  //return _(input).replace('.', ',').concat(' ', '€');
  return _(input).toNumber().toFixed(2).replace('.', ',').concat(' ', '€')
}
