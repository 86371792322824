import { connect } from 'react-redux'

import { requestPasswordChange } from '../../../state/actions/auth'
import ChangePassword from '../../presentational/ChangePassword/ChangePassword'

const mapStateToProps = (state) => {
  const { error } = state.auth
  return {
    error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitChangePassword: (username, oldPassword, password) => {
      dispatch(requestPasswordChange(username, oldPassword, password))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
