import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, Button } from '@material-ui/core'
import _ from 'lodash'

class EndpointForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderUrl: '',
      orderItemUrl: '',
      user: '',
      pass: '',
    }

    this.onChange = this.onChange.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      orderUrl: _.get(nextProps, 'endpoint.orderUrl', ''),
      orderItemUrl: _.get(nextProps, 'endpoint.orderItemUrl', ''),
      user: _.get(nextProps, 'endpoint.user', ''),
      pass: _.get(nextProps, 'endpoint.pass', ''),
    })
  }

  onChange(name, event) {
    this.setState({ [name]: event.target.value })
  }

  onClickSubmit(event) {
    this.props.onSubmitForm(this.state.orderUrl, this.state.orderItemUrl, this.state.user, this.state.pass)
  }

  allowSubmit() {
    return this.state.orderUrl.length > 0 && this.state.user.length > 0 && this.state.pass.length > 0
  }

  render() {
    return (
      <Grid container spacing={24} direction="column">
        <Grid item>
          <TextField
            id="orderUrl"
            label="Order URL"
            value={this.state.orderUrl}
            onChange={(e) => this.onChange('orderUrl', e)}
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <TextField
            id="orderItemsUrl"
            label="Order Items URL"
            value={this.state.orderItemUrl}
            onChange={(e) => this.onChange('orderItemUrl', e)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            id="user"
            label="Authentication Username"
            value={this.state.user}
            onChange={(e) => this.onChange('user', e)}
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <TextField
            id="pass"
            label="Authentication Password"
            value={this.state.pass}
            onChange={(e) => this.onChange('pass', e)}
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <Button id="submit" color="primary" type="submit" onClick={this.onClickSubmit} disabled={!this.allowSubmit()} variant="contained">
            {this.props.buttonText}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

EndpointForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  endpoint: PropTypes.object,
}

export default EndpointForm
