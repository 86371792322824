import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

import RefundProductRow from '../RefundProductRow/RefundProductRow'

export class RefundProductsList extends React.Component {
  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>EAN</TableCell>
            <TableCell>Ordered quantity</TableCell>
            <TableCell>Return quantity</TableCell>
            <TableCell>Return Code Web</TableCell>
            <TableCell>Return Code Additional</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.products.map((product) => (
            <RefundProductRow key={product.id} product={product} onRowChanged={this.props.onProductRowChanged} {...this.props} />
          ))}
        </TableBody>
      </Table>
    )
  }
}

RefundProductsList.propTypes = {
  onProductRowChanged: PropTypes.func.isRequired,
  products: PropTypes.array,
  refundPercentages: PropTypes.array,
  genericRow: PropTypes.bool,
}
