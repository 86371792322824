import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Grid, Toolbar, Button } from '@material-ui/core'
import _ from 'lodash'

import UserAccountForm from '../UserAccountForm/UserAccountForm'
import { UserSchema } from '../../../schema'
import { getFinnishFormattedDate } from '../../../utils/formatting'
import styles from './styles'

class ModifyUser extends React.Component {
  constructor(props) {
    super(props)

    this.onClickUpdate = this.onClickUpdate.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this)
  }

  componentDidMount() {
    this.props.onRequestUser(this.props.match.params.username)
  }

  onClickUpdate(email, username, firstName, lastName, selectedRole, warehouse, refundPolicies) {
    this.props.onSubmitUpdate(email, firstName, lastName, warehouse, refundPolicies)
  }

  onClickDelete(event) {
    this.props.onSubmitDelete(this.props.user.username)
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h5" component="h1">
            Modify User
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Typography variant="caption">Account Created</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{getFinnishFormattedDate(_.get(this.props, 'user.userCreateDate', ''))}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Account Modified</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{getFinnishFormattedDate(_.get(this.props, 'user.userLastModifiedDate', ''))}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Typography variant="caption">User Status</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{_.get(this.props, 'user.userStatus', '-')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <Toolbar className={classes.toolbar}>
              <Button color="secondary" onClick={this.onClickDelete} variant="contained">
                Delete
              </Button>
            </Toolbar>
          </Grid>
          <Grid item>
            <UserAccountForm buttonText="Update" onSubmitForm={this.onClickUpdate} {...this.props} modifyMode />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

ModifyUser.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestUser: PropTypes.func.isRequired,
  onSubmitUpdate: PropTypes.func.isRequired,
  onSubmitDelete: PropTypes.func.isRequired,
  availableRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: UserSchema,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(ModifyUser)
