import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid, TextField, Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'

import FormErrorMessage from '../FormErrorMessage/FormErrorMessage'
import styles from './styles'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      errorUsername: false,
      password: '',
      errorPassword: false,
    }

    this.onChangeUsername = this.onChangeUsername.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onClickLogin = this.onClickLogin.bind(this)
  }

  onChangeUsername(event) {
    this.setState({
      errorUsername: false,
      username: event.target.value,
    })
  }

  onChangePassword(event) {
    this.setState({
      errorPassword: false,
      password: event.target.value,
    })
  }

  onClickLogin(event) {
    event.preventDefault()
    if (this.state.username.length === 0 || this.state.password.length === 0) {
      this.setState({
        errorUsername: this.state.username.length === 0,
        errorPassword: this.state.password.length === 0,
      })
      return
    }

    this.props.onSubmitLogin(this.state.username, this.state.password)
  }

  render() {
    const { classes } = this.props
    const { redirectToReferrer, router, tokens } = this.props

    if (!_.isUndefined(router.location.state) && redirectToReferrer && tokens) {
      return <Redirect to={router.location.state.from.pathname} />
    }

    return (
      <form>
        <Grid className={classes.root} container justify="center" direction="column">
          <Grid item>
            <Typography color="inherit" variant="h5" component="h1">
              Login
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={16} justify="center" direction="column">
              <Grid item>
                <TextField
                  id="username"
                  label="Username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  error={this.state.errorUsername}
                  required
                  fullWidth
                  autoComplete="on"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  error={this.state.errorPassword}
                  required
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              {this.props.error !== null && (
                <Grid item>
                  <FormErrorMessage message={this.props.error} />
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={8} justify="space-around" alignItems="center">
                  <Grid item>
                    <Button color="primary" id="login" type="submit" onClick={this.onClickLogin} variant="contained">
                      Login
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography color="primary" component={Link} to="/forgotpassword">
                      Forgot credentials
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

Login.propTypes = {
  onSubmitLogin: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(Login)
