import { RSAA } from 'redux-api-middleware'
import { push } from 'connected-react-router'
import * as ActionTypes from './types/endpoints'

export function requestEndpointsList() {
  return {
    [RSAA]: {
      endpoint: process.env.REACT_APP_ENDPOINTS_ENDPOINT,
      method: 'GET',
      types: [ActionTypes.REQUEST_ENDPOINTS, ActionTypes.REQUEST_ENDPOINTS_SUCCESS, ActionTypes.REQUEST_ENDPOINTS_FAILURE],
    },
  }
}

export function requestEndpoint(endpointId) {
  return {
    [RSAA]: {
      endpoint: `${process.env.REACT_APP_ENDPOINTS_ENDPOINT}/endpoint/${endpointId}`,
      method: 'GET',
      types: [ActionTypes.REQUEST_ENDPOINT, ActionTypes.REQUEST_ENDPOINT_SUCCESS, ActionTypes.REQUEST_ENDPOINT_FAILURE],
    },
  }
}

export function createEndpoint(orderUrl, orderItemUrl, user, pass) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_ENDPOINTS_ENDPOINT}/endpoint`,
        method: 'POST',
        types: [ActionTypes.CREATE_ENDPOINT, ActionTypes.CREATE_ENDPOINT_SUCCESS, ActionTypes.CREATE_ENDPOINT_FAILURE],
        body: JSON.stringify({
          orderUrl,
          orderItemUrl,
          user,
          pass,
        }),
      },
    })

    if (actionResponse.error) {
      // TODO: Proper error handling
      return actionResponse
    }

    // everything went ok, so move to endpoints listing
    dispatch(push('/endpoints'))
    return actionResponse
  }
}

export function updateEndpoint(endpointId, orderUrl, orderItemUrl, user, pass) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_ENDPOINTS_ENDPOINT}/endpoint/${endpointId}`,
        method: 'POST',
        types: [ActionTypes.UPDATE_ENDPOINT, ActionTypes.UPDATE_ENDPOINT_SUCCESS, ActionTypes.UPDATE_ENDPOINT_FAILURE],
        body: JSON.stringify({
          orderUrl,
          orderItemUrl,
          user,
          pass,
        }),
      },
    })

    if (actionResponse.error) {
      // TODO: Proper error handling
      return actionResponse
    }

    // everything went ok, so move to endpoints listing
    dispatch(push('/endpoints'))
    return actionResponse
  }
}

export function deleteEndpoint(id) {
  return {
    [RSAA]: {
      endpoint: `${process.env.REACT_APP_ENDPOINTS_ENDPOINT}/endpoint/${id}`,
      method: 'DELETE',
      types: [ActionTypes.DELETE_ENDPOINT, ActionTypes.DELETE_ENDPOINT_SUCCESS, ActionTypes.DELETE_ENDPOINT_FAILURE],
    },
  }
}
