import { RSAA } from 'redux-api-middleware'

import * as ActionTypes from './types/refresh'

export const attemptTokenRefresh = (refreshToken) => {
  return {
    [RSAA]: {
      endpoint: `${process.env.REACT_APP_AUTH_ENDPOINT}/refreshtoken`,
      method: 'POST',
      types: [ActionTypes.REQUEST_REFRESH_TOKEN, ActionTypes.REQUEST_REFRESH_TOKEN_SUCCESS, ActionTypes.REQUEST_REFRESH_TOKEN_FAILURE],
      body: JSON.stringify({
        refreshToken,
      }),
    },
  }
}

export const saveRefreshPromise = (promise) => ({
  type: ActionTypes.SAVE_REFRESH_PROMISE,
  payload: promise,
  meta: undefined,
})

export const clearRefreshPromise = () => ({
  type: ActionTypes.CLEAR_REFRESH_PROMISE,
  payload: undefined,
  meta: undefined,
})
