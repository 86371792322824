import React from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  LinearProgress,
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'

import UsersListToolbar from '../UsersListToolbar/UsersListToolbar'

import { UserSchema } from '../../../schema'
import { getFinnishFormattedDate } from '../../../utils/formatting'
import styles from './styles'

class ListUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
    }

    this.onUserRowClicked = this.onUserRowClicked.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this)
  }

  componentWillMount() {
    this.props.onRefreshUsersList()
  }

  onUserRowClicked(event, username) {
    this.props.history.push(`/users/${username}`)
  }

  onChangePage(event, page) {
    this.setState({ page })
  }

  onChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value })
  }

  render() {
    const { classes, loading } = this.props
    return (
      <div>
        <UsersListToolbar />
        {!loading && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Modified</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Warehouse</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.users
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((user) => {
                  const id = user.username
                  return (
                    <TableRow key={id} hover onClick={(event) => this.onUserRowClicked(event, user.username)}>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{getFinnishFormattedDate(user.userCreateDate)}</TableCell>
                      <TableCell>{getFinnishFormattedDate(user.userLastModifiedDate)}</TableCell>
                      <TableCell>
                        {user.enabled ? <AccountCircle className={classes.enabled} /> : <AccountCircle className={classes.disabled} />}
                      </TableCell>
                      <TableCell>{user.warehouse}</TableCell>
                      <TableCell>
                        {user.surname} {user.name}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  count={this.props.users.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.onChangePage}
                  onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
        {loading && <LinearProgress />}
      </div>
    )
  }
}

ListUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  onRefreshUsersList: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(UserSchema),
  loading: PropTypes.bool,
}

ListUsers.defaultProps = {
  users: [],
  loading: false,
}

export default withStyles(styles, { withTheme: true })(ListUsers)
