import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Toolbar, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

import styles from './styles'

class UsersListToolbar extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Toolbar className={classes.root}>
        <Button color="primary" variant="contained" component={Link} to="/users/create">
          Create New User
        </Button>
      </Toolbar>
    )
  }
}

UsersListToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UsersListToolbar)
