import React from 'react'
import { withStyles, Typography, AppBar, Toolbar } from '@material-ui/core'

import styles from './styles'
import AccountMenu from '../../container/AccountMenu'
import NavigationTabs from '../../container/NavigationTabs'

class Header extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <Typography className={classes.title} variant="h6">
              Return Form
            </Typography>
            <NavigationTabs />
            <AccountMenu />
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Header)
