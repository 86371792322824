import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Typography,
  LinearProgress,
} from '@material-ui/core'

import EndpoinstListToolbar from '../EndpointsListToolbar/EndpointsListToolbar'
import { EndPointSchema } from '../../../schema'

class ListEndpoints extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
    }

    this.onChange = this.onChange.bind(this)
    this.onRowClicked = this.onRowClicked.bind(this)
  }

  componentWillMount() {
    this.props.onRefreshEndpoints()
  }

  onChange(name, event) {
    this.setState({ [name]: event.target.value })
  }

  onRowClicked(event, endpoint) {
    this.props.history.push(`/endpoints/${endpoint}`)
  }

  render() {
    const { loading } = this.props
    return (
      <div>
        <Typography variant="h5" component="h1">
          Endpoint Management
        </Typography>
        <EndpoinstListToolbar />
        {!loading && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order Endpoint</TableCell>
                <TableCell>Order Items Endpoint</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Password</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.endpoints
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((value) => (
                  <TableRow key={value.endpointId} hover onClick={(event) => this.onRowClicked(event, value.endpointId)}>
                    <TableCell>{value.orderUrl}</TableCell>
                    <TableCell>{value.orderItemUrl}</TableCell>
                    <TableCell>{value.user}</TableCell>
                    <TableCell>{value.pass}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  count={this.props.endpoints.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={(e) => this.onChange('page', e)}
                  onChangeRowsPerPage={(e) => this.onChange('rowsPerPage', e)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
        {loading && <LinearProgress />}
      </div>
    )
  }
}

ListEndpoints.propTypes = {
  endpoints: PropTypes.arrayOf(EndPointSchema),
  onRefreshEndpoints: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

ListEndpoints.defaultProps = {
  endpoints: [],
  loading: false,
}

export default ListEndpoints
