import { connect } from 'react-redux'

import { searchOrders } from '../../../state/actions/orders'
import SearchOrders from '../../presentational/SearchOrders/SearchOrders'

const mapStateToProps = (state) => {
  const { error, loading } = state.orders
  return {
    error,
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSubmitSearch: (orderId) => {
    dispatch(searchOrders(orderId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrders)
