import React from 'react'
import { Typography } from '@material-ui/core'

class NotFound extends React.Component {
  render() {
    return (
      <Typography variant="h5" component="h1">
        Page not found
      </Typography>
    )
  }
}

export default NotFound
