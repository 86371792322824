import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'

class Authenticated extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }

    this.onClickLogout = this.onClickLogout.bind(this)
    this.onMenuClick = this.onMenuClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
  }

  onClickLogout(event) {
    this.props.onSubmitLogout()
    this.handleMenuClose(event)
  }

  onMenuClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose(event) {
    this.setState({ anchorEl: null })
  }

  render() {
    const open = Boolean(this.state.anchorEl)
    return (
      <div>
        <IconButton aria-owns={open ? 'menu-list' : null} aria-haspopup="true" aria-label="Menu" onClick={this.onMenuClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-list"
          anchorEl={this.state.anchorEl}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleMenuClose}
        >
          <MenuItem onClick={this.handleMenuClose} component={Link} to={`/account/${this.props.username}`}>
            Account
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              this.handleMenuClose(e)
              this.onClickLogout(e)
            }}
            component={Link}
            to="/"
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

Authenticated.propTypes = {
  onSubmitLogout: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

export default Authenticated
