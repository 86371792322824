import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, MenuItem, Button } from '@material-ui/core'
import _ from 'lodash'

import { UserSchema } from '../../../schema'
import { validateEmail } from '../../../utils/validators'
import FormErrorMessage from '../FormErrorMessage/FormErrorMessage'
import DropDownSelect from '../DropDownSelect/DropDownSelect'

class UserAccountForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      invalidEmail: false,
      confirmEmail: '',
      emailMismatch: false,
      username: '',
      firstName: '',
      lastName: '',
      warehouse: '',
      selectedRole: '',
      selectRefundPolicies: [],
    }

    this.onChange = this.onChange.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangeConfirmEmail = this.onChangeConfirmEmail.bind(this)
    this.onChangeUsername = this.onChangeUsername.bind(this)
    this.onChangeRole = this.onChangeRole.bind(this)
    this.onChangeRefundPolicies = this.onChangeRefundPolicies.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      email: _.get(nextProps, 'user.email', ''),
      confirmEmail: _.get(nextProps, 'user.email', ''),
      username: _.get(nextProps, 'user.username', ''),
      firstName: _.get(nextProps, 'user.name', ''),
      lastName: _.get(nextProps, 'user.surname', ''),
      warehouse: _.get(nextProps, 'user.warehouse', ''),
      selectedRole: _.get(nextProps, 'user.group', ''),
      selectRefundPolicies: _.get(nextProps, 'user.Item.refundPolicies', []),
    })
  }

  onChange(name, event) {
    this.setState({ [name]: event.target.value })
  }

  onChangeEmail(event) {
    const { value } = event.target
    this.setState({
      email: value,
      invalidEmail: value.length === 0 || !validateEmail(value),
      emailMismatch: this.state.confirmEmail !== event.target.value,
    })
  }

  onChangeConfirmEmail(event) {
    this.setState({
      confirmEmail: event.target.value,
      emailMismatch: this.state.email !== event.target.value,
    })
  }

  onChangeUsername(event) {
    if (!this.props.modifyMode) {
      this.setState({ username: event.target.value })
    }
  }

  onChangeRole(event) {
    if (!this.props.modifyMode) {
      this.setState({ selectedRole: event.target.value })
    }
  }

  onChangeRefundPolicies(event) {
    this.setState({ selectRefundPolicies: event.target.value })
  }

  onClickSubmit(event) {
    event.preventDefault()
    this.props.onSubmitForm(
      this.state.email,
      this.state.username,
      this.state.firstName,
      this.state.lastName,
      this.state.selectedRole,
      this.state.warehouse,
      this.state.selectRefundPolicies,
    )
  }

  allowSubmit() {
    if (
      this.state.email.length === 0 ||
      this.state.firstName.length === 0 ||
      this.state.lastName.length === 0 ||
      this.state.warehouse.length === 0 ||
      this.state.selectRefundPolicies === 0
    ) {
      return false
    }
    if (!this.props.modifyMode && (this.state.username.length === 0 || this.state.selectedRole.length === 0)) {
      return false
    }
    if (this.state.emailMismatch || this.state.invalidEmail) {
      return false
    }
    return true
  }

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <TextField
            id="email"
            label="Email address"
            value={this.state.email}
            onChange={this.onChangeEmail}
            error={this.state.invalidEmail}
            required
            fullWidth
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="confirm-email"
            label="Confirm Email Address"
            value={this.state.confirmEmail}
            onChange={this.onChangeConfirmEmail}
            error={this.state.emailMismatch}
            required
            fullWidth
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="username"
            label="Username"
            value={this.state.username}
            onChange={this.onChangeUsername}
            disabled={this.props.modifyMode}
            required
            fullWidth
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="firstName"
            label="First Name"
            value={this.state.firstName}
            onChange={(e) => this.onChange('firstName', e)}
            required
            fullWidth
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="lastName"
            label="Last Name"
            value={this.state.lastName}
            onChange={(e) => this.onChange('lastName', e)}
            required
            fullWidth
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="warehouse"
            label="Warehouse"
            value={this.state.warehouse}
            onChange={(e) => this.onChange('warehouse', e)}
            required
            fullWidth
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id="role"
            label="User Role"
            value={this.state.selectedRole}
            onChange={this.onChangeRole}
            disabled={this.props.modifyMode}
            required
            fullWidth
            select
          >
            {this.props.availableRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={6}>
          <DropDownSelect
            multiple
            selectedValues={this.state.selectRefundPolicies}
            availableRefundPolicies={this.props.availableRefundPolicies}
            onChangeRefundPolicies={this.onChangeRefundPolicies}
          />
        </Grid>
        {this.props.error !== null && (
          <Grid item xs={12}>
            <FormErrorMessage message={this.props.error} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button id="submit" color="primary" type="submit" onClick={this.onClickSubmit} disabled={!this.allowSubmit()} variant="contained">
            {this.props.buttonText}
          </Button>
        </Grid>
      </Grid>
    )
  }
}

UserAccountForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  availableRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: UserSchema,
  error: PropTypes.string,
}

export default UserAccountForm
// export default withStyles(styles, { withTheme: true })(UserAccountForm);
