import React from 'react'
import { Select, Input, InputLabel, Checkbox, ListItemText, withStyles, FormControl, MenuItem } from '@material-ui/core'
import _ from 'lodash'

import styles from './styles'

const DropDownSelect = ({ selectedValues, onChangeRefundPolicies, availableRefundPolicies, classes }) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor="tag-multiple">Return percentages</InputLabel>
    <Select
      multiple
      value={selectedValues}
      onChange={onChangeRefundPolicies}
      input={<Input id="name-multiple" />}
      renderValue={(selected) => selected.join(', ')}
    >
      {availableRefundPolicies.map((refundPolicy) => (
        <MenuItem key={refundPolicy} value={refundPolicy}>
          <Checkbox checked={_.includes(selectedValues, refundPolicy)} />
          <ListItemText primary={refundPolicy} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default withStyles(styles, { withTheme: true })(DropDownSelect)
