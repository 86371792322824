import { RSAA, isRSAA } from 'redux-api-middleware'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'

import { attemptTokenRefresh, saveRefreshPromise, clearRefreshPromise } from '../../state/actions/refresh'
import { setAccessTokens, clearTokens } from '../../state/actions/auth'
import { REQUEST_REFRESH_TOKEN } from '../../state/actions/types/refresh'

const tokenRefresh = (store) => (next) => (action) => {
  // We're only interested in actions tagged with RSAA (a.k.a. API calling)
  if (!isRSAA(action)) {
    return next(action)
  }
  // Don't continue if we're dealing with refresh RSAA (infinite loop)
  if (isRefreshActionCall(action)) {
    return next(action)
  }

  const state = store.getState()
  // Check if the refresh action is already pending (Promise exists)
  let refreshPromise = _.get(state, 'refresh.refreshPromise', null)
  if (!_.isNull(refreshPromise) && !_.isEmpty(refreshPromise)) {
    return refreshPromise.then(() => next(action))
  }

  const accessToken = _.get(state, 'auth.tokens.AccessToken', null)
  // No point to try refresh if there is no access token
  if (!accessToken) {
    return next(action)
  }
  // No point to try refresh if the token is not expired
  if (!isExpired(accessToken)) {
    return next(action)
  }
  refreshPromise = requestRefresh(store, next)
  next(saveRefreshPromise(refreshPromise))

  return refreshPromise.then((response) => {
    if (!response.error) {
      return store.dispatch(action)
    }

    return store.dispatch(clearTokens()).then((response) => ({ error: true }))
  })
}

const requestRefresh = (store, next) => {
  const RefreshToken = _.get(store.getState(), 'auth.tokens.RefreshToken')
  return store.dispatch(attemptTokenRefresh(RefreshToken)).then((response) => {
    // No matter if the refresh succeeds or fails, clear the promise
    next(clearRefreshPromise())

    // On success we want to save the new tokens
    if (!response.error) {
      next(setAccessTokens(response.payload))
    }
    return response
  })
}

const isExpired = (accessToken) => {
  if (!accessToken) {
    // Return expired if the token is not available
    return true
  }
  const decoded = jwtDecode(accessToken)
  // In AWS Cognito the token 'exp' is in seconds, need to take that into account here
  const expInMs = _.toNumber(_.get(decoded, 'exp', 0) * 1000)
  return new Date() > new Date(expInMs)
}

const isRefreshActionCall = (action) => {
  return _.includes(action[RSAA].types, REQUEST_REFRESH_TOKEN)
}

export default tokenRefresh
