import PropTypes from 'prop-types'

export const UserSchema = PropTypes.shape({
  enabled: PropTypes.bool,
  group: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  userCreateDate: PropTypes.string,
  userLastModifiedDate: PropTypes.string,
  userStatus: PropTypes.string,
  username: PropTypes.string,
})

export const EndPointSchema = PropTypes.shape({
  endPointId: PropTypes.string,
  orderItemUrl: PropTypes.string,
  orderUrl: PropTypes.string,
  pass: PropTypes.string,
  user: PropTypes.string,
})
