import { red } from '@material-ui/core/colors'

const style = (theme) => ({
  root: {
    backgroundColor: red['A700'],
    padding: theme.spacing.unit * 2,
    margin: theme.spacing.unit,
  },
  text: {
    color: 'white',
  },
})

export default style
