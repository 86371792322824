import React from 'react'
import { withStyles, Paper } from '@material-ui/core'

import styles from './styles'

import Routes from '../../routes'

class Main extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Paper elevation={4} className={classes.root}>
        <Routes />
      </Paper>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Main)
