import { connect } from 'react-redux'

import { createUser } from '../../../state/actions/user'
import CreateUser from '../../presentational/CreateUser/CreateUser'

const mapStateToProps = (state) => {
  const { error } = state.user
  return {
    availableRoles: ['Admin', 'Directsuppliers', 'Shopusers'],
    availableRefundPolicies: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitCreateUser: (email, userName, firstName, lastName, role, warehouse, refundPolicies) => {
      dispatch(createUser(email, userName, firstName, lastName, role, warehouse, refundPolicies))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
