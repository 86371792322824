import { connect } from 'react-redux'
import get from 'lodash/get'

import ListOrders from '../../presentational/ListOrders/ListOrders'

const mapStateToProps = (state) => {
  return {
    orders: get(state, 'orders.orders', []),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOrders)
