const style = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    midWidth: '320px',
    maxWidth: '1400px',
    margin: 'auto',
    padding: theme.spacing.unit,
  },
})

export default style
