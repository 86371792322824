import { connect } from 'react-redux'

import { confirmUserForgottenPassword } from '../../../state/actions/auth'
import ResetPassword from '../../presentational/ResetPassword/ResetPassword'

const mapStateToProps = (state) => {
  const { error } = state.auth
  return {
    error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitResetPassword: (username, verificationCode, password) => {
      dispatch(confirmUserForgottenPassword(username, verificationCode, password))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
