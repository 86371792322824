import { connect } from 'react-redux'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'

import { createRefund, getOrder } from '../../../state/actions/orders'
import { requestMyAccount } from '../../../state/actions/user'

import CreateRefund from '../../presentational/CreateRefund/CreateRefund'

const mapStateToProps = (state) => {
  let refundPolicies = _.get(state, 'user.myAccount.Item.refundPolicies', null)
  // Manipulate the array to satisfy dropdown
  refundPolicies = _.map(refundPolicies, (policy) => {
    return { key: policy, value: policy, label: `${policy} %` }
  })
  const idToken = _.get(state, 'auth.tokens.IdToken', null)
  const decoded = idToken ? jwtDecode(idToken) : {}
  const username = _.get(decoded, 'cognito:username', null)
  const role = _.head(_.get(decoded, 'cognito:groups', []))
  const fullname = `${_.get(decoded, 'family_name')}, ${_.get(decoded, 'name')}`
  return {
    order: state.orders.refundOrder,
    username,
    fullname,
    role,
    refundPercentages: refundPolicies,
    // These old returnCodesWeb and returnCodesAdditional separate values are not in use anymore,
    // these are here just to serve as a documentation for old cases in db.
    // returnCodesWeb: [
    //   { key: 1, value: 1, text: '1- Tuote ei vastannut odotuksia' },
    //   { key: 2, value: 3, text: '2- Väärä tuote' }, // Value is != key in purpose
    //   { key: 3, value: 2, text: '3- Takuupalautus' }, // Value is != key in purpose
    //   { key: 4, value: 4, text: '4- Tuote rikki' },
    //   { key: 5, value: 5, text: '5- Muu syy' },
    // ],
    // returnCodesAdditional: [
    //   // { key: 1, value: 1, text: 'Myyntikuntoinen tuote' },
    //   // { key: 2, value: 2, text: '2. Vaihtotuote' },
    //   // { key: 3, value: 3, text: '3. Myyntikunnoton asiakkaan virheestä' },
    //   // { key: 4, value: 4, text: '4. Laatuvirhe / vaihdettava tuote' },
    //   // { key: 5, value: 5, text: '5. Takuu-huollettava tuote' },
    //   // { key: 6, value: 6, text: '6. Kuljetusvaurio' },
    // ],
    returnCodes: [
      {
        key: 6,
        value: 6,
        text: 'Tuote joko avaamaton TAI avattu ja kokeiltu asianmukaisesti',
        additional: [
          {
            key: 7,
            value: 7,
            text: 'Tuote avaamaton tai siihen tutustuttu asianmukaisesti',
          },
          {
            key: 8,
            value: 8,
            text: 'Tuote on ollut rikki/virheellinen jo varastolta lähtiessä',
          },
          {
            key: 9,
            value: 9,
            text: 'Tuote on tai vaikuttaa olevan kuljetuksessa vaurioitunut',
          },
        ],
      },
      {
        key: 7,
        value: 7,
        text: 'Tuote myyntikelvoton ASIAKKAAN TOIMESTA',
        additional: [{ key: 10, value: 10, text: 'Tarkempi kuvaus Comments-kentässä' }],
      },
    ],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrder: (orderId) => {
      dispatch(getOrder(orderId))
    },
    onSubmitRefund: (orderId, comments, accountNumber, returnItems, directSuppliersName, returnMakerEmail) => {
      // Clean the return items to only contain data required by the backend
      const items = _.map(returnItems, (item) =>
        _.pick(item, [
          'returnItemId',
          'orderItemId',
          'status',
          'notifiedQuantity',
          'returnCodeWeb',
          'returnCodeAdditional',
          'returnRefundPercent',
          'maintenanceReclamation',
        ]),
      )
      dispatch(createRefund(orderId, comments, accountNumber, items, directSuppliersName, returnMakerEmail))
    },
    onGetUser: () => {
      dispatch(requestMyAccount())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRefund)
