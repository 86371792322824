import { connect } from 'react-redux'

import { requestLogin } from '../../../state/actions/auth'
import Login from '../../presentational/Login/Login'
import _ from 'lodash'

const mapStateToProps = (state) => {
  const { error } = state.auth
  return {
    redirectToReferrer: state.auth.redirectToReferrer,
    router: state.router,
    tokens: _.get(state, 'auth.tokens', null),
    error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitLogin: (username, password, callback) => {
      dispatch(requestLogin(username, password))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
