import createReducer from '../utils/createReducer'
import * as ActionTypes from '../actions/types/endpoints'

export const initialState = {
  endpoints: [],
  endpoint: null,
  loading: false,
}

export default createReducer(initialState, {
  [ActionTypes.REQUEST_ENDPOINTS](state) {
    return {
      ...state,
      endpoints: initialState.endpoints,
      loading: true,
    }
  },
  [ActionTypes.REQUEST_ENDPOINTS_SUCCESS](state, action) {
    return {
      ...state,
      endpoints: action.payload.message,
      loading: false,
    }
  },
  [ActionTypes.REQUEST_ENDPOINTS_FAILURE](state) {
    return {
      ...state,
      loading: false,
    }
  },
  [ActionTypes.REQUEST_ENDPOINT](state) {
    return {
      ...state,
      endpoint: initialState.endpoint,
      loading: true,
    }
  },
  [ActionTypes.REQUEST_ENDPOINT_SUCCESS](state, action) {
    return {
      ...state,
      endpoint: action.payload.message,
      loading: false,
    }
  },
  [ActionTypes.REQUEST_ENDPOINT_FAILURE](state) {
    return {
      ...state,
      loading: false,
    }
  },
})
