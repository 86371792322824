import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, Button, Typography, withStyles } from '@material-ui/core'

import styles from './styles'
import FormErrorMessage from '../FormErrorMessage/FormErrorMessage'

class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      errorUsername: false,
      oldPassword: '',
      errorOldPassword: false,
      password: '',
      errorPassword: false,
    }

    this.onChangeUsername = this.onChangeUsername.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangeOldPassword = this.onChangeOldPassword.bind(this)
    this.onClickChangePassword = this.onClickChangePassword.bind(this)
  }

  onChangeUsername(event) {
    this.setState({
      errorUsername: false,
      username: event.target.value,
    })
  }

  onChangePassword(event) {
    this.setState({
      errorPassword: false,
      password: event.target.value,
    })
  }

  onChangeOldPassword(event) {
    this.setState({
      errorOldPassword: false,
      oldPassword: event.target.value,
    })
  }

  onClickChangePassword(event) {
    event.preventDefault()
    if (this.state.username.length === 0 || this.state.password.length === 0) {
      this.setState({
        errorUsername: this.state.username.length === 0,
        errorPassword: this.state.password.length === 0,
        errorOldPassword: this.state.oldPassword.length === 0,
      })
      return
    }

    this.props.onSubmitChangePassword(this.state.username, this.state.oldPassword, this.state.password)
  }

  render() {
    const { classes } = this.props
    return (
      <form>
        <Grid className={classes.root} container justify="center" direction="column">
          <Grid item>
            <Typography variant="body2">This is your first time logging in, please change your password</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={16} justify="center" direction="column">
              <Grid item>
                <TextField
                  id="username"
                  label="Username"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  error={this.state.errorUsername}
                  required
                  fullWidth
                  autoComplete="on"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="oldPassword"
                  type="password"
                  label="Old password"
                  value={this.state.oldPassword}
                  onChange={this.onChangeOldPassword}
                  error={this.state.errorPassword}
                  required
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="password"
                  type="password"
                  label="New password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  error={this.state.errorPassword}
                  required
                  fullWidth
                  autoComplete="off"
                />
              </Grid>
              {this.props.error !== null && (
                <Grid item>
                  <FormErrorMessage message={this.props.error} />
                </Grid>
              )}
              <Grid item>
                <Grid container spacing={8} justify="space-around" alignItems="center">
                  <Grid item>
                    <Button id="login" type="submit" onClick={this.onClickChangePassword} variant="contained" color="primary">
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

ChangePassword.propTypes = {
  onSubmitChangePassword: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(ChangePassword)
