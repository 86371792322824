import { connect } from 'react-redux'
import _ from 'lodash'

import { removeNotification } from '../../../state/actions/notifications'

import NotificationMessage from '../../presentational/NotificationMessage/NotificationMessage'

const mapStateToProps = (state) => {
  return {
    message: _.head(state.notifications.messages),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseNotificationMessage: (notificationId) => {
      dispatch(removeNotification(notificationId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMessage)
