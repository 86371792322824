import React from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@material-ui/core'
import { Link } from 'react-router-dom'
import _ from 'lodash'

class NavigationTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    }

    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: _.includes(nextProps.path, '/orders')
        ? 0
        : _.includes(nextProps.path, '/users')
          ? 1
          : _.includes(nextProps.path, '/endpoints')
            ? 2
            : 0,
    })
  }

  onChange(event, value) {
    this.setState({ value })
  }

  render() {
    if (!this.props.showTabs) {
      return null
    }
    return (
      <Tabs value={this.state.value} onChange={this.onChange}>
        <Tab component={Link} to="/orders/search" label="Returns" />
        {this.props.showUsers && <Tab component={Link} to="/users" label="Users" />}
        {this.props.showEndpoints && <Tab component={Link} to="/endpoints" label="Endpoints" />}
      </Tabs>
    )
  }
}

NavigationTabs.propTypes = {
  showTabs: PropTypes.bool,
  showUsers: PropTypes.bool,
  showEndpoints: PropTypes.bool,
}

export default NavigationTabs
