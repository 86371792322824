import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createHashHistory } from 'history'

import AppReducers from './reducers'
import middlewares from './middlewares'

const persistStoreConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  debug: process.env.NODE_ENV === 'development',
}
const history = createHashHistory()
const reducers = persistCombineReducers(persistStoreConfig, AppReducers(history))

/**
 * Creates the store and applies middlewares to it
 * @param {Object} initialState Store initial state
 */
export default function configureStore(initialState) {
  const store = createStore(reducers, initialState, applyMiddleware(...middlewares(history)))
  const persistor = persistStore(store)

  return { persistor, store, history }
}
