import _ from 'lodash'

import createReducer from '../utils/createReducer'
import * as ActionTypes from '../actions/types/notifications'

export const initialState = {
  messages: [],
}

export default createReducer(initialState, {
  [ActionTypes.ADD_NOTIFICATION_SUCCESS](state, action) {
    return {
      ...state,
      messages: _.concat(state.messages, action.payload),
    }
  },
  [ActionTypes.REMOVE_NOTIFICATION_SUCCESS](state, action) {
    return {
      ...state,
      messages: _.pull(state.messages, action.payload),
    }
  },
})
