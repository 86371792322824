import { green, red } from '@material-ui/core/colors'

const style = (theme) => ({
  enabled: {
    color: green[500],
  },
  disabled: {
    color: red[500],
  },
})

export default style
