import _ from 'lodash'

import createReducer from '../utils/createReducer'
import * as ActionTypes from '../actions/types/auth'

export const initialState = {
  tokens: null,
  redirectToReferrer: false,
  error: null,
  refreshPromise: null,
}

export default createReducer(initialState, {
  [ActionTypes.REQUEST_LOGIN](state) {
    return {
      ...state,
      tokens: null,
      error: null,
    }
  },
  [ActionTypes.REQUEST_LOGIN_SUCCESS](state, action) {
    return {
      ...state,
      redirectToReferrer: true,
      tokens: _.get(action, 'payload.message.AuthenticationResult', null),
    }
  },
  [ActionTypes.REQUEST_LOGIN_FAILURE](state, action) {
    return {
      ...state,
      redirectToReferrer: false,
      error: action.payload.response.message,
    }
  },
  [ActionTypes.REQUEST_LOGOUT](state, action) {
    return {
      ...state,
      redirectToReferrer: false,
      error: null,
    }
  },
  [ActionTypes.REQUEST_LOGOUT_SUCCESS](state, action) {
    return {
      ...state,
      tokens: null,
      redirectToReferrer: false,
    }
  },
  [ActionTypes.REQUEST_LOGOUT_FAILURE](state, action) {
    return {
      ...state,
      redirectToReferrer: false,
    }
  },
  [ActionTypes.REQUEST_PASSWORD_CHANGE](state, action) {
    return {
      ...state,
      error: null,
    }
  },
  [ActionTypes.REQUEST_PASSWORD_CHANGE_FAILURE](state, action) {
    return {
      ...state,
      error: action.payload.response.message,
    }
  },
  [ActionTypes.REQUEST_PASSWORD_CHANGE_SUCCESS](state, action) {
    return {
      ...state,
      tokens: _.get(action, 'payload.message.AuthenticationResult', null),
    }
  },
  [ActionTypes.REQUEST_PASSWORD_RESET](state) {
    return {
      ...state,
      error: null,
    }
  },
  [ActionTypes.REQUEST_PASSWORD_RESET_FAILURE](state, action) {
    return {
      ...state,
      error: action.payload.response.message,
    }
  },
  [ActionTypes.REQUEST_PASSWORD_RESET_CHANGE](state) {
    return {
      ...state,
      error: null,
    }
  },
  [ActionTypes.REQUEST_PASSWORD_RESET_CHANGE_FAILURE](state, action) {
    return {
      ...state,
      error: action.payload.response.message,
    }
  },
  [ActionTypes.SET_ACCESS_TOKENS](state, action) {
    return {
      ...state,
      tokens: _.merge({}, state.tokens, _.get(action, 'payload.message.AuthenticationResult', null)),
    }
  },
  [ActionTypes.CLEAR_ACCESS_TOKENS](state) {
    return {
      ...state,
      tokens: null,
    }
  },
})
