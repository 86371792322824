import { get } from 'lodash'
import createReducer from '../utils/createReducer'
import * as ActionTypes from '../actions/types/orders'

export const initialState = {
  orders: [],
  refundOrder: null,
  error: null,
  loading: false,
}

export default createReducer(initialState, {
  [ActionTypes.SEARCH_ORDERS](state) {
    return {
      ...state,
      orders: [],
      error: null,
      loading: true,
    }
  },
  [ActionTypes.SEARCH_ORDERS_SUCCESS](state, action) {
    return {
      ...state,
      orders: action.payload.message,
      // For some reason (that I do not understand) RefundProductRow constructor preserves
      // some construction time values and uses them widely in the component, e.g. merges
      // original to new values before notifying change in the form.
      // Thus refundOrder must be cleared immediately when we receive new results to avoid
      // using previous search value as a basis in constructor of RefundProductRow leading to
      // behavior described in ESCM-2397.
      // I feel that the correct fix would be to rewrite RefundProductRow, but I do not dare
      // to do that. Instead I believe clearing the refundOrder in this phase is good enough
      // solution.
      refundOrder: null,
      loading: false,
    }
  },
  [ActionTypes.SEARCH_ORDERS_FAILURE](state, action) {
    return {
      ...state,
      error: get(action, 'payload.response.message', initialState.error),
      loading: false,
    }
  },
  [ActionTypes.GET_ORDER](state) {
    return {
      ...state,
      refundOrder: null,
      loading: true,
    }
  },
  [ActionTypes.GET_ORDER_SUCCESS](state, action) {
    return {
      ...state,
      refundOrder: action.payload,
      loading: false,
    }
  },
  [ActionTypes.GET_ORDER_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
    }
  },
  [ActionTypes.CREATE_GENERIC_REFUND](state) {
    return {
      ...state,
      loading: true,
    }
  },
  [ActionTypes.CREATE_REFUND_SUCCESS](state) {
    return {
      ...state,
      refundOrder: null,
      orders: [],
      loading: false,
    }
  },
  [ActionTypes.CREATE_GENERIC_REFUND_FAILURE](state) {
    return {
      ...state,
      loading: false,
    }
  },
  [ActionTypes.CREATE_GENERIC_REFUND_SUCCESS](state) {
    return {
      ...state,
      refundOrder: null,
      orders: [],
      loading: false,
    }
  },
})
