import { connect } from 'react-redux'

import { requestUser, updateUser, deleteUser } from '../../../state/actions/user'
import ModifyUser from '../../presentational/ModifyUser/ModifyUser'

const mapStateToProps = (state) => {
  const { error } = state.user
  return {
    user: state.user.user,
    availableRoles: ['Admin', 'Directsuppliers', 'Shopusers'],
    availableRefundPolicies: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestUser: (username) => {
      dispatch(requestUser(username))
    },
    onSubmitUpdate: (email, firstName, lastName, warehouse, refundPolicies) => {
      dispatch(updateUser(email, firstName, lastName, warehouse, refundPolicies, true))
    },
    onSubmitDelete: (username) => {
      dispatch(deleteUser(username))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyUser)
