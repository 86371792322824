import { get } from 'lodash'
import createReducer from '../utils/createReducer'
import * as ActionTypes from '../actions/types/user'

export const initialState = {
  users: [],
  user: null,
  error: null,
  myAccount: null,
  loading: false,
}

export default createReducer(initialState, {
  [ActionTypes.CREATE_USER](state) {
    return {
      ...state,
      error: initialState.error,
      loading: true,
    }
  },
  [ActionTypes.CREATE_USER_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
    }
  },
  [ActionTypes.CREATE_USER_FAILURE](state, action) {
    return {
      ...state,
      error: get(action, 'payload.message', initialState.error),
      loading: false,
    }
  },
  [ActionTypes.REQUEST_USERS](state) {
    return {
      ...state,
      users: initialState.users,
      loading: true,
    }
  },
  [ActionTypes.REQUEST_USERS_SUCCESS](state, action) {
    return {
      ...state,
      users: get(action, 'payload.message.Users', initialState.users),
      loading: false,
    }
  },
  [ActionTypes.REQUEST_USERS_FAILURE](state) {
    return {
      ...state,
      loading: false,
    }
  },
  [ActionTypes.REQUEST_USER](state) {
    return {
      ...state,
      user: initialState.user,
      error: initialState.error,
      loading: true,
    }
  },
  [ActionTypes.REQUEST_USER_SUCCESS](state, action) {
    return {
      ...state,
      user: get(action, 'payload.message', initialState.user),
      loading: false,
    }
  },
  [ActionTypes.REQUEST_USER_FAILURE](state, action) {
    return {
      ...state,
      error: get(action, 'payload.response.message', initialState.error),
      loading: false,
    }
  },
  [ActionTypes.UPDATE_USER](state) {
    return {
      ...state,
      error: initialState.error,
      loading: true,
    }
  },
  [ActionTypes.UPDATE_USER_SUCCESS](state) {
    return {
      ...state,
      loading: false,
    }
  },
  [ActionTypes.UPDATE_USER_FAILURE](state, action) {
    return {
      ...state,
      error: get(action, 'payload.message', initialState.error),
      loading: false,
    }
  },
  [ActionTypes.REQUEST_MY_ACCOUNT](state) {
    return {
      ...state,
      myAccount: initialState.myAccount,
      error: initialState.error,
      loading: true,
    }
  },
  [ActionTypes.REQUEST_MY_ACCOUNT_SUCCESS](state, action) {
    return {
      ...state,
      myAccount: get(action, 'payload.message', initialState.myAccount),
      loading: false,
    }
  },
  [ActionTypes.REQUEST_MY_ACCOUNT_FAILURE](state, action) {
    return {
      ...state,
      error: get(action, 'payload.message', initialState.error),
      loading: false,
    }
  },
})
