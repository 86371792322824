import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AdminRoute from '../container/AdminRoute'
import PrivateRoute from '../container/PrivateRoute'

import NotFound from '../presentational/NotFound/NotFound'
import Login from '../container/Login'

import ForgotPassword from '../container/ForgotPassword'
import ChangePassword from '../container/ChangePassword'
import ResetPassword from '../container/ResetPassword'
import ListUsers from '../container/ListUsers'
import CreateUser from '../container/CreateUser'
import ModifyUser from '../container/ModifyUser'
import SearchOrders from '../container/SearchOrders'
import ListOrders from '../container/ListOrders'
import CreateRefund from '../container/CreateRefund'
import ListEndpoints from '../container/ListEndpoints'
import CreateEndpoint from '../container/CreateEndpoint'
import ModifyEndpoint from '../container/ModifyEndpoint'
import Account from '../container/Account'

const routes = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/changepassword" component={ChangePassword} />
    <Route exact path="/forgotpassword" component={ForgotPassword} />
    <Route exact path="/resetpassword" component={ResetPassword} />
    <PrivateRoute exact path="/orders" component={ListOrders} />
    <PrivateRoute path="/orders/search" component={SearchOrders} />
    <PrivateRoute path="/orders/refund/:orderId" component={CreateRefund} />
    <AdminRoute exact path="/users" component={ListUsers} />
    <AdminRoute path="/users/create" component={CreateUser} />
    <AdminRoute path="/users/:username" component={ModifyUser} />
    <AdminRoute exact path="/endpoints" component={ListEndpoints} />
    <AdminRoute path="/endpoints/create" component={CreateEndpoint} />
    <AdminRoute path="/endpoints/:endpointId" component={ModifyEndpoint} />
    <PrivateRoute path="/account/:username" component={Account} />
    <Route component={NotFound} />
  </Switch>
)

export default routes
