import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, TableRow, TableCell, TextField, MenuItem } from '@material-ui/core'
import _ from 'lodash'

import styles from './styles'

class RefundProductRow extends React.Component {
  constructor(props) {
    super(props)

    const { product } = props
    // Obs! Note written while fixing ESCM-2397.
    // Preserving a copy of these values at construction time is very error prone e.g. in the situation
    // when search subject (i.e. order id) changes leading to these values belonging to completely
    // different order.
    this.state = {
      notifiedQuantity: _.get(product, 'notifiedQuantity', ''),
      returnCodeWeb: _.get(product, 'returnCodeWeb', ''),
      returnCodeAdditional: _.get(product, 'returnCodeAdditional', ''),
      productName: _.get(product, 'productName', ''),
      eanCode: _.get(product, 'eanCode', ''),
      quantity: _.get(product, 'quantity', ''),
      status: '1', // This is constant required by API, do not change or remove
      isValid: true, // By default empty row is considered valid
    }

    this.onChange = this.onChange.bind(this)
    this.onChangeReturnCodeWeb = this.onChangeReturnCodeWeb.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onChangeNumber = this.onChangeNumber.bind(this)
    this.onChangeReturnNumber = this.onChangeReturnNumber.bind(this)
  }

  /**
   * Checks if this product row is incomplete. Row is considered incomplete if
   * any of it's required fields contains anything but all required fields are
   * not filled.
   * @returns {bool} whether row is incomplete (started but not finished)
   */
  isInComplete() {
    const { productName, eanCode, quantity, notifiedQuantity, returnCodeWeb, returnCodeAdditional } = this.state
    let requiredFields = [notifiedQuantity, returnCodeWeb, returnCodeAdditional]
    if (this.props.genericRow) {
      requiredFields = _.concat(requiredFields, [productName, eanCode, quantity])
    }
    const emptyFields = _.compact(_.map(requiredFields, (value) => _.isEmpty(_.toString(value))))
    return emptyFields.length !== 0 && emptyFields.length !== requiredFields.length
  }

  async onChange(name, event) {
    await this.setState({
      [name]: event.target.value,
    })
    this.rowChanged()
  }

  async onChangeReturnCodeWeb(event) {
    await this.setState({
      returnCodeWeb: event.target.value,
      returnCodeAdditional: '',
    })
    this.rowChanged()
  }

  async onChangeNumber(name, event) {
    await this.setState({
      [name]: !_.isEmpty(event.target.value) ? _.toInteger(Math.abs(event.target.value)) : '',
    })
    this.rowChanged()
  }

  async onChangeReturnNumber(name, event) {
    // Number cannot exceed quantity
    const quantity = _.toInteger(this.state.quantity)
    const value = !_.isEmpty(event.target.value) ? Math.min(quantity, _.toInteger(Math.abs(event.target.value))) : ''
    await this.setState({
      [name]: value,
    })
    this.rowChanged()
  }

  async onCheckboxChange(name, event) {
    await this.setState({
      [name]: event.target.checked,
    })
    this.rowChanged()
  }

  async checkValidity() {
    this.setState({
      isValid: !this.isInComplete(),
    })
  }

  async rowChanged() {
    await this.checkValidity()
    // ^ Since we're immediately reading state values and passing them on, we need to await for the change to happen
    this.props.onRowChanged(this.props.product.id, _.merge(this.props.product, this.state))
  }

  render() {
    const { classes, product } = this.props

    const activeReturnCode = this.props.returnCodes.find((code) => code.value === this.state.returnCodeWeb)
    const availableReturnCodesAdditional = activeReturnCode ? activeReturnCode.additional : []

    return (
      <TableRow>
        <TableCell>
          {!this.props.genericRow ? (
            product.productName
          ) : (
            <TextField
              id={`product-name-${product.id}`}
              onChange={(e) => this.onChange('productName', e)}
              value={this.state.productName}
              error={this.isInComplete() && this.state.productName.length <= 0}
            />
          )}
        </TableCell>
        <TableCell>
          {!this.props.genericRow ? (
            product.eanCode
          ) : (
            <TextField
              id={`ean-code-${product.id}`}
              onChange={(e) => this.onChange('eanCode', e)}
              value={this.state.eanCode}
              error={this.isInComplete() && this.state.eanCode.length <= 0}
            />
          )}
        </TableCell>
        <TableCell>
          {!this.props.genericRow ? (
            product.quantity
          ) : (
            <TextField
              id={`quantity-${product.id}`}
              className={classes.amountNumberText}
              onChange={(e) => this.onChangeNumber('quantity', e)}
              value={this.state.quantity}
              type="number"
              inputProps={{
                min: 0,
              }}
              error={this.isInComplete() && this.state.quantity.length <= 0}
            />
          )}
        </TableCell>
        <TableCell>
          <TextField
            id={`notified-quantity-${product.id}`}
            className={classes.amountNumberText}
            onChange={(event) => this.onChangeReturnNumber('notifiedQuantity', event)}
            value={this.state.notifiedQuantity}
            type="number"
            inputProps={{
              min: 0,
            }}
            error={this.isInComplete() && this.state.notifiedQuantity.length <= 0}
          />
        </TableCell>
        <TableCell>
          <TextField
            id={`return-code-web-${product.id}`}
            className={classes.returnCodeText}
            onChange={(event) => this.onChangeReturnCodeWeb(event)}
            value={this.state.returnCodeWeb}
            select
            error={this.isInComplete() && this.state.returnCodeWeb.length <= 0}
          >
            {this.props.returnCodes.map((code) => {
              const { additional, ...returnCodeWeb } = code
              return <MenuItem {...returnCodeWeb}>{returnCodeWeb.text}</MenuItem>
            })}
          </TextField>
        </TableCell>
        <TableCell>
          <TextField
            id={`return-code-additional-${product.id}`}
            className={classes.returnCodeText}
            onChange={(event) => this.onChange('returnCodeAdditional', event)}
            value={this.state.returnCodeAdditional}
            select
            error={this.isInComplete() && this.state.returnCodeAdditional.length <= 0}
          >
            {availableReturnCodesAdditional.map((additionalCode) => (
              <MenuItem {...additionalCode}>{additionalCode.text}</MenuItem>
            ))}
          </TextField>
        </TableCell>
      </TableRow>
    )
  }
}

RefundProductRow.propTypes = {
  classes: PropTypes.object.isRequired,
  onRowChanged: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  returnCodes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      additional: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
}
export default withStyles(styles)(RefundProductRow)
