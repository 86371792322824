import { connect } from 'react-redux'

import { createEndpoint } from '../../../state/actions/endpoints'
import CreateEndpoint from '../../presentational/CreateEndpoint/CreateEndpoint'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitCreateEndpoint: (orderUrl, orderItemUrl, username, password) => {
      dispatch(createEndpoint(orderUrl, orderItemUrl, username, password))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEndpoint)
