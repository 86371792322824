import { connect } from 'react-redux'
import _ from 'lodash'

import PrivateRoute from '../../presentational/PrivateRoute/PrivateRoute'

const mapStateToProps = (state) => {
  const hasTokens = !_.isNull(_.get(state, 'auth.tokens', null))
  return {
    isAuthenticated: hasTokens,
    isAuthorized: hasTokens,
  }
}

export default connect(mapStateToProps)(PrivateRoute)
