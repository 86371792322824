import _ from 'lodash'

import makeActionCreator from '../utils/makeActionCreator'
import * as ActionTypes from './types/notifications'

let notificationId = 0

const addNotificationSuccess = makeActionCreator(ActionTypes.ADD_NOTIFICATION_SUCCESS, 'payload', 'meta')
const addNotificationFailure = makeActionCreator(ActionTypes.ADD_NOTIFICATION_FAILURE, 'payload', 'meta', 'error')

/**
 *
 * @param {string} message message to display on notification
 * @param {number} timeOut how long to display the notification (in ms), defaults to 5000ms
 */
export function addNotification(message, timeOut = 5000) {
  return (dispatch) => {
    if (_.isEmpty(message)) {
      return dispatch(
        addNotificationFailure({
          message: 'Cannot add notification with empty message',
        }),
      )
    } else if (_.isNaN(timeOut) || timeOut < 0) {
      return dispatch(
        addNotificationFailure({
          message: `Invalid timeOut '${timeOut}', must be positive number`,
        }),
      )
    }
    // Should be good to display
    notificationId += 1
    const msgObject = {
      notificationId,
      message,
      timeOut,
    }
    dispatch({ type: ActionTypes.ADD_NOTIFICATION, payload: msgObject, meta: undefined })
    return dispatch(addNotificationSuccess(msgObject, undefined))
  }
}

const removeNotificationSuccess = makeActionCreator(ActionTypes.REMOVE_NOTIFICATION_SUCCESS, 'payload', 'meta')
const removeNotificationFailure = makeActionCreator(ActionTypes.REMOVE_NOTIFICATION_FAILURE, 'payload', 'meta', 'error')

export function removeNotification(notificationId) {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.REMOVE_NOTIFICATION, payload: notificationId, meta: undefined })
    const msg = _.find(_.get(getState(), 'notifications.messages', []), (msg) => _.isEqual(msg.notificationId, notificationId))
    if (!_.isUndefined(msg)) {
      return dispatch(removeNotificationSuccess(msg, undefined))
    } else {
      return dispatch(
        removeNotificationFailure(
          {
            message: `Could not find notification with id ${notificationId}`,
          },
          undefined,
          true,
        ),
      )
    }
  }
}
