import { connect } from 'react-redux'

import { requestLogout } from '../../../state/actions/auth'
import Authenticated from '../../presentational/Authenticated/Authenticated'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitLogout: () => {
      dispatch(requestLogout())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated)
