import { RSAA } from 'redux-api-middleware'
import { push } from 'connected-react-router'
import _ from 'lodash'
import jwtDecode from 'jwt-decode'

import { addNotification } from './notifications'
import * as ActionTypes from './types/auth'

export function requestLogin(username, password) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_AUTH_ENDPOINT}/login`,
        method: 'POST',
        types: [ActionTypes.REQUEST_LOGIN, ActionTypes.REQUEST_LOGIN_SUCCESS, ActionTypes.REQUEST_LOGIN_FAILURE],
        body: JSON.stringify({
          username,
          password,
        }),
      },
    })

    if (actionResponse.error) {
      // in case of forgotten / reset password, only exception is returned. if user is reset, redirect to resetpassword form
      if (_.isEqual(_.get(actionResponse, 'payload.response.message'), 'PasswordResetRequiredException')) {
        dispatch(push('/resetpassword'))
      }
      return actionResponse
    }

    // if this is a fresh user, redirect to change password
    if (actionResponse.payload.message.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(push('/changepassword'))
      return actionResponse
    }

    // otherwise redirect to orders search
    dispatch(push('/orders/search'))
    return actionResponse
  }
}

export function requestPasswordChange(username, oldPassword, newPassword) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_USER_CRUD_ENDPOINT}/changePassword`,
        method: 'POST',
        types: [
          ActionTypes.REQUEST_PASSWORD_CHANGE,
          ActionTypes.REQUEST_PASSWORD_CHANGE_SUCCESS,
          ActionTypes.REQUEST_PASSWORD_CHANGE_FAILURE,
        ],
        body: JSON.stringify({
          username,
          oldPassword,
          newPassword,
        }),
      },
    })

    if (actionResponse.error) {
      // TODO: Proper error handling
      return actionResponse
    }

    dispatch(addNotification('Password changed succesfully!'))
    // redirect to orders search
    dispatch(push('/orders/search'))

    return actionResponse
  }
}

export function requestResetPassword(username) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_USER_CRUD_ENDPOINT}/resetUserPassword`,
        method: 'POST',
        types: [ActionTypes.REQUEST_PASSWORD_RESET, ActionTypes.REQUEST_PASSWORD_RESET_SUCCESS, ActionTypes.REQUEST_PASSWORD_RESET_FAILURE],
        body: JSON.stringify({
          username,
        }),
      },
    })

    if (actionResponse.error) {
      // TODO: Proper error handling
      return actionResponse
    }

    dispatch(addNotification('Password reset requested, check your email.'))

    // if went ok, redirect to reset password form
    dispatch(push('/resetpassword'))

    return actionResponse
  }
}

export function confirmUserForgottenPassword(username, verificationCode, password) {
  return async (dispatch) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_USER_CRUD_ENDPOINT}/confirmUserForgottenPassword`,
        method: 'POST',
        types: [
          ActionTypes.REQUEST_PASSWORD_RESET_CHANGE,
          ActionTypes.REQUEST_PASSWORD_RESET_CHANGE_SUCCESS,
          ActionTypes.REQUEST_PASSWORD_RESET_CHANGE_FAILURE,
        ],
        body: JSON.stringify({
          username,
          verificationCode,
          password,
        }),
      },
    })

    if (actionResponse.error) {
      return actionResponse
    }

    dispatch(addNotification('Password reset succesfull!'))

    // if went ok, redirect to login
    dispatch(push('/'))

    return actionResponse
  }
}

export function requestLogout() {
  return (dispatch, getState) => {
    const IdToken = _.get(getState(), 'auth.tokens.IdToken', null)
    let username = ''
    if (!_.isNull(IdToken)) {
      const decoded = jwtDecode(IdToken)
      username = _.get(decoded, 'cognito:username', '')
    }
    return dispatch({
      [RSAA]: {
        endpoint: `${process.env.REACT_APP_AUTH_ENDPOINT}/logout`,
        method: 'POST',
        types: [ActionTypes.REQUEST_LOGOUT, ActionTypes.REQUEST_LOGOUT_SUCCESS, ActionTypes.REQUEST_LOGOUT_FAILURE],
        body: JSON.stringify({
          username,
        }),
      },
    })
  }
}

export function setAccessTokens(tokens) {
  return {
    type: ActionTypes.SET_ACCESS_TOKENS,
    payload: tokens,
    meta: undefined,
  }
}

export function clearTokens() {
  return {
    type: ActionTypes.CLEAR_ACCESS_TOKENS,
    payload: undefined,
    meta: undefined,
  }
}
