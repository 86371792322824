import { connect } from 'react-redux'

import { requestEndpointsList } from '../../../state/actions/endpoints'
import ListEndpoints from '../../presentational/ListEndpoints/ListEndpoints'

const mapStateToProps = (state) => ({
  endpoints: state.endpoints.endpoints,
  loading: state.endpoints.loading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    onRefreshEndpoints: () => {
      dispatch(requestEndpointsList())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListEndpoints)
