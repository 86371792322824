import { connectRouter } from 'connected-react-router'

import authReducer from './auth'
import userReducer from './user'
import ordersReducer from './orders'
import endpointsReducer from './endpoints'
import notificationsReducer from './notifications'
import refreshReducer from './refresh'

const reducers = (history) => ({
  router: connectRouter(history),
  auth: authReducer,
  user: userReducer,
  orders: ordersReducer,
  endpoints: endpointsReducer,
  notifications: notificationsReducer,
  refresh: refreshReducer,
})

export default reducers
