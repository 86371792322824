import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'

import AccountMenu from '../../presentational/AccountMenu/AccountMenu'

const mapStateToProps = (state) => {
  const tokens = _.get(state, 'auth.tokens', null)
  const idToken = _.get(tokens, 'IdToken', null)
  return {
    isAuthenticated: !_.isNull(tokens),
    username: idToken ? _.get(jwtDecode(idToken), 'cognito:username', null) : '',
  }
}

export default connect(mapStateToProps, null)(AccountMenu)
