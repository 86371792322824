import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, TextField, Button, withStyles } from '@material-ui/core'
import _ from 'lodash'

import { RefundProductsList } from '../RefundProductsList/RefundProductsList'

import { getFinnishFormattedDate } from '../../../utils/formatting'
import styles from './styles'

class RefundForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      productRows: _.get(props, 'products', []),
      comments: '',
      accountNumber: '',
      billingAddress: {},
      shippingAddress: {},
      directSuppliersName: '',
      returnMakerEmail: '',
      validForm: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onProductsRowChanged = this.onProductsRowChanged.bind(this)
    this.addProductRow = this.addProductRow.bind(this)
    this.onChangeBillingAddress = this.onChangeBillingAddress.bind(this)
    this.onChangeShippingAddress = this.onChangeShippingAddress.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      productRows: _.get(nextProps, 'products', []),
    })
  }

  onChange(name, event) {
    this.setState({ [name]: event.target.value })
  }

  onChangeBillingAddress(address) {
    this.setState({ billingAddress: address })
  }

  onChangeShippingAddress(address) {
    this.setState({ shippingAddress: address })
  }

  onClickSubmit(event) {
    event.preventDefault()
    let products = this.getCleanProducts()
    if (this.props.genericRefund) {
      // We're using fake id's when creating generic refunds so we
      // do not want them to clash on API, therefore drop them
      products = _.map(products, (product) => _.omit(product, 'id'))
    } else {
      // API expects id's with different name, change it
      products = _.map(products, (product) => _.set(product, 'orderItemId', product.id))
    }
    this.props.onSubmit({
      comments: this.state.comments,
      accountNumber: this.state.accountNumber,
      returnItems: products,
      billingAddress: this.state.billingAddress,
      shippingAddress: this.state.shippingAddress,
      directSuppliersName: this.state.directSuppliersName,
      returnMakerEmail: this.state.returnMakerEmail,
    })
  }

  getCleanProducts() {
    return _.filter(_.compact(this.state.productRows), (product) => product.isValid)
  }

  allRowsValid() {
    return _.filter(this.state.productRows, (product) => !_.isUndefined(product.isValid) && !product.isValid).length === 0
  }

  onProductsRowChanged(id, productRow) {
    const prod = _.merge({}, productRow, { id })
    let newRows = this.state.productRows
    const index = _.findIndex(newRows, (p) => p.id === prod.id)
    if (index !== -1) {
      // Item exists replace
      newRows[index] = prod
    } else {
      newRows = _.concat(newRows, prod)
    }
    this.setState({ productRows: newRows })
  }

  addProductRow(event) {
    this.setState({
      ...this.state,
      productRows: _.concat(this.state.productRows, { id: this.state.productRows.length }),
    })
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Typography variant="h5" component="h1">
            {_.get(this.props, 'headline', '')}
          </Typography>
        </Grid>
        {!this.props.genericRefund && (
          <Grid item>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">Order Number:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">{_.get(this.props, 'order.id', '-')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!this.props.genericRefund && (
          <Grid item>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">Order Date:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">{getFinnishFormattedDate(_.get(this.props, 'order.orderDate'))}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">Seller:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{_.get(this.props.order, 'seller', '-')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">Return creator name:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{_.get(this.props, 'fullname', '-')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1">Return creator username:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{_.get(this.props, 'username', '-')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <RefundProductsList
            onProductRowChanged={this.onProductsRowChanged}
            products={this.state.productRows}
            refundPercentages={this.props.refundPercentages}
            genericRow={this.props.genericRefund}
            returnCodes={this.props.returnCodes}
            role={this.props.role}
          />
        </Grid>
        {this.props.genericRefund && (
          <Grid item>
            <Button onClick={this.addProductRow} color="secondary" variant="contained">
              Add Row
            </Button>
          </Grid>
        )}
        <Grid item>
          <TextField
            inputProps={{
              maxLength: 4000,
            }}
            id="comments"
            label="Comments"
            onChange={(e) => this.onChange('comments', e)}
            value={this.state.comments}
            multiline
            fullWidth
            rows={3}
            rowsMax={3}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="space-around">
            <Grid item xs={6}>
              <TextField
                id="directSuppliersName"
                label="Direct Suppliers Name"
                onChange={(e) => this.onChange('directSuppliersName', e)}
                value={this.state.directSuppliersName}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="returnMakerEmail"
                label="Return Maker Email"
                onChange={(e) => this.onChange('returnMakerEmail', e)}
                value={this.state.returnMakerEmail}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.forcedDoublePadding} item>
          <Button id="submit-refund" onClick={this.onClickSubmit} color="primary" variant="contained" disabled={!this.allRowsValid()}>
            Create Return
          </Button>
        </Grid>
        <Grid className={classes.forcedDoublePadding} style={{ 'text-align': 'left' }} item>
          <Typography variant="h5" component="h2">
            Hei palautusportaalin käyttäjä!
          </Typography>
          <p>
            Lomakkeelle on tehty muutos, jonka jälkeen myös ei-myyntikuntoisen tuotteen käsittely ja kommentin jättäminen lomakkeen kautta
            on mahdollista. Erillistä sähköpostia asiakaspalvelulle ei kommentin jättämisen lisäksi tarvita, ellei palautuksessa ole jotain
            sellaista poikkeuksellista virhettä, josta tulee lähettää asiakaspalvelulle kuvia. (Katso alta ohje)
          </p>
          <Typography variant="h6" component="h3">
            Yleisimpien palautustapausten ohjeita:
          </Typography>
          <ul style={{ 'margin-left': 30 }}>
            <li>
              <b>Tuote 100% myyntikuntoinen ja pakkaus avaamaton.</b> Käsittele tuote tällä lomakkeella ja valitse pudotusvalikosta
              vaihtoehto ”Tuote avaamaton tai kokeiltu asianmukaisesti”
            </li>
            <li>
              <b>Pakkaus avattu asianmukaisesti ja siististi, ja tuote käyttämätön ja myyntikuntoinen.</b> Käsittele tuote tällä lomakkeella
              ja valitse vaihtoehto ”Tuote avaamaton tai kokeiltu asianmukaisesti”
            </li>
            <li>
              <b>Tuote on avattu hygieniatuote tai sinetöity tuote, jonka sinetti avattu.</b> Käsittele tuote tällä lomakkeella ja valitse
              vaihtoehto ”Tuote myyntikelvoton ASIAKKAAN TOIMESTA”. Kirjoita kommenttikenttään, mikä tuotteessa vikana. SOK:n aspa kontaktoi
              tämän jälkeen asiakkaan.
            </li>
            <li>
              <b>
                Tuotteen pakkaus on revitty käyttökelvottomaksi tai tuotteeseen on tutustuttu tai käytetty niin, ettei se ole tämän vuoksi
                myyntikelpoinen.
              </b>{' '}
              Käsittele tuote tällä lomakkeella ja valitse vaihtoehto ”Tuote myyntikelvoton ASIAKKAAN TOIMESTA”. Kirjoita kommenttikenttään,
              miksi tuote myyntikelvoton. Prisman / Sokoksen aspa kontaktoi tämän jälkeen asiakkaan ja tekee lopullisen päätöksen
              hyvitetäänkö tuote asiakkaalle. Huom! Tarvittaessa lähetä kuvia asiakaspalvelulle osoitteeseen:{' '}
              <a href="mailto:asiakaspalvelu.prisma@sok.fi">asiakaspalvelu.prisma@sok.fi</a> TAI{' '}
              <a href="mailto:asiakaspalvelu.sokos@sok.fi">asiakaspalvelu.sokos@sok.fi</a>
            </li>
            <li>
              <b>Tuote vaikuttaa vaurioituneen kuljetuksessa tai olleen rikki jo varastolta lähtiessä.</b> Valitse sopiva vaihtoehto: Tuote
              ollut virheellinen jo varastolta lähtiessä tai Tuote vaikuttaa vaurioituneen kuljetuksessa.
            </li>
          </ul>
          <Typography variant="h6" component="h3">
            Tärkeää
          </Typography>
          <p>
            Tämä lomake hoitaa hyvityksen <b>kuluttajalle</b>. Lomakkeen lisäksi käynnistä myös normaali hyvitysprosessi SOK:n ja
            tavarantoimittajan välillä sovitulla laskutustavalla.
          </p>
          <p>Säilytä ei-myyntikuntoinen tuote kunnes SOK:n aspa ilmoittaa, kuinka tuotteen suhteen toimitaan.</p>
          <p>
            Lomaketta koskevat ongelmatilanteet ja kysymykset: <a href="mailto:mari.edwards@sok.fi">mari.edwards@sok.fi</a>
          </p>
        </Grid>
      </Grid>
    )
  }
}

RefundForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  headline: PropTypes.string,
}

export default withStyles(styles)(RefundForm)
