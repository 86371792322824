import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper, Typography } from '@material-ui/core'

import styles from './styles'

class FormErrorMessage extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Paper elevation={4} color="error" className={classes.root}>
        <Typography className={classes.text} variant="body2">
          {this.props.message}
        </Typography>
      </Paper>
    )
  }
}

FormErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
}

export default withStyles(styles)(FormErrorMessage)
