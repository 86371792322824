import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter, TablePagination } from '@material-ui/core'

class ListOrders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      rowsPerPage: 10,
    }

    this.onChange = this.onChange.bind(this)
    this.onRowClicked = this.onRowClicked.bind(this)
  }

  onChange(name, event) {
    this.setState({ [name]: event.target.value })
  }

  onRowClicked(id, event) {
    this.props.history.push(`/orders/${id}`)
  }

  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Found Orders</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.orders
            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
            .map((order) => {
              return (
                <TableRow key={order.id} hover onClick={(event) => this.onRowClicked(event, order.id)}>
                  <TableCell>{order.id}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              count={this.props.orders.length}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={(event) => this.onChange('page', event)}
              onChangeRowsPerPage={(event) => this.onChange('rowsPerPage', event)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}

ListOrders.propTypes = {
  orders: PropTypes.array.isRequired,
}

export default ListOrders
