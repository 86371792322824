import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'

import NavigationTabs from '../../presentational/NavigationTabs/NavigationTabs'

function showAdminTabs(token) {
  if (_.isNull(token) || _.isUndefined(token)) {
    return false
  }
  const decoded = jwtDecode(token)
  const groups = _.get(decoded, 'cognito:groups')
  return _.includes(groups, 'Admin')
}

const mapStateToProps = (state) => {
  const tokens = _.get(state, 'auth.tokens', null)
  const idToken = _.get(tokens, 'IdToken', null)
  const adminTabs = showAdminTabs(idToken)
  return {
    showTabs: !_.isNull(tokens) && !_.isEmpty(tokens),
    showUsers: adminTabs,
    showEndpoints: adminTabs,
    path: state.router.location.pathname,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabs)
